<template>
<TopNav />
<SideBar />
  <main>
  <div class="container-fluid mt-5 pt-5 ">
    <h4>Yeni Proje</h4>
    <div class="mb-3 col-md-4" >
      <label for="unvan" class="form-label">Başlık</label>
      <input
        type="text"
        class="form-control"
        id="unvan"
        v-model="name"
      />
      <label for="description" class="form-label">Açıklama</label>
      <textarea class="form-control" id="description" rows="4" v-model="description"></textarea>
      <label for="start_date" class="form-label">Başlangıç</label>
      <input type="date" class="form-control" id="start_date" v-model="start_date" />
      <label for="end_date" class="form-label">Bitiş</label>
      <input type="date" class="form-control" id="end_date" v-model="end_date" />
      <br/>
      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="is_active">
      <label class="form-check-label" for="flexCheckDefault">
          Aktif mi?
      </label><br/><br/>
      <label for="type" class="form-label">Türü</label>
      <select v-model="type" id="type" class="form-control form-select">
        <option
          v-for="type in project_types"
          :key="type.id"
          :value="type.id"
        >
          {{ type.name }}
        </option>
      </select>
      <label for="period" class="form-label">Periyod</label>
      <select v-model="period" id="period" class="form-control  form-select">
        <option
          v-for="period in this.project_periods"
          :key="period.id"
          :value="period.id"
        >
          {{ period.period }}
        </option>
      </select>
      <label for="client" class="form-label">Müşteri</label>
      <select v-model="client_id" id="client" class="form-control">
        <option
          v-for="client in clients"
          :key="client.id"
          :value="client.id"
        >
          {{ client.name }}
        </option>
      </select>
      <br />
      <button class="btn btn-primary btn-block" @click="editClient">
        Ekle
      </button>
    </div>
  </div>
  </main>
</template>
<script>
import ClientDataService from "../services/ClientDataService";
import ProjectDataService from "../services/ProjectDataService";
import SideBar from "@/components/SideBar";
import TopNav from "@/components/TopNav";

import { mapState } from "vuex";

export default {
  name: 'ProjectEdit',
  emits: ["edited"],
  props: ["currentProject"],
  computed: mapState(["project_periods", "project_types"]),
  data() {
    return {
      selected: -1,
      name: null,
      description: null,
      start_date: null,
      end_date: null,
      is_active: null,
      type: null,
      period: null,
      client_id: null,
      clients: []
    };
  },
  created() {
    ClientDataService.getAll().then(response => {
      this.clients = response.data
    }).catch(e => {
      console.log(e.response.data);
        })
    console.log(this.project_types)
  },
 components: {
    SideBar,
    TopNav 
  },
  watch: {
    currentProject: function (newV, oldV) {
      this.project = newV;
      
      oldV;
    },
  },
  methods: {
    editClient() {
      console.log(this.start_date+'jk')
      let data = {
        name:this.name,
        description:this.description,
        start_date:this.start_date,
        end_date:this.end_date,
        is_active:this.is_active,
        type:this.type,
        period:this.period,
        client_id:this.client_id,
      }
      ProjectDataService.create(data)
      .then(response => {
        console.log(response.data)
        this.$emit("edited")
        this.$notify({
          text: "Proje Eklendi",
          type: "success",
        });
      }).catch(e => {
        console.log(e.response.data)
      })
    },
  },
};
</script>