<template>
    <div class="card" style="width: 30rem">
      <div class="card-header">#{{ currentProject.id }}|{{ currentProject.name }}</div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <strong>Başlangıç:</strong> {{ currentProject.start_date }}
        </li>
        <li class="list-group-item">
          <strong>Bitiş:</strong> {{ currentProject.end_date }}
        </li>
        <li class="list-group-item">
          <strong>Müşteri:</strong> {{ currentClient.name }}
        </li>
        <li class="list-group-item">
          <strong>Aktif mi?:</strong> {{ currentProject.is_active }}
        </li>
        <li class="list-group-item">
          <strong>Türü:</strong> {{ currentProject["project_type.name"] }}
        </li>
        <li class="list-group-item">
          <strong>Dönemi:</strong> {{ currentProject["project_period.period"] }}
        </li>
      </ul>
      <p class="card-text pl-3 ml-3">
        &emsp;<strong>Açıklama:</strong><br />
        &emsp;{{ currentProject.description }}
      </p>
    </div>
</template>
<script>
export default {
    name: 'ProjectBox',
    props: ["currentProject", "currentClient"],
    
    mounted(){
        console.log(this.currentClient)
        this.client = this.currentClient 
        this.project = this.currentProject

    }
    
}
</script>