<template>
  <div class="row">
    <div class="mb-3 col-auto" v-if="income">
      <label for="hour" class="form-label">Süre (Saat)</label>
      <input type="number" class="form-control" id="hour" v-model="income.hour" />
      <label for="date_" class="form-label">Tarihi</label>
      <input
        type="date"
        class="form-control"
        id="date_"
        v-model="income.date_"
      />
    
      <label for="client_id" class="form-label">Müşteri</label>
      <select v-model="income.client_id" id="client" class="form-control  form-select">
        <option
          v-for="client in clients"
          :key="client.id"
          :value="client.id"
        >
          {{ client.name }}
        </option>
      </select>
      <label for="unvan" class="form-label">note</label>
      <input
        type="text"
        class="form-control"
        id="note"
        v-model="income.note"
      />
      <br />
      
      <br />
      <button class="btn btn-primary btn-block" @click="editClient">
        Duzenle
      </button>
    </div>
  </div>
</template>
<script>

import AnketDataService from "../../services/AnketDataService";

import { mapState } from "vuex";

export default {
  name: "IncomeEdit",
  emits: ["edited"],
  props: ["currentIncome"],
  computed: mapState(["project_periods", "project_types", "client_groups"]),
  data() {
    return {
      selected: -1,
      income: this.currentIncome,
      clients: this.client_groups,
    };
  },
  created() {
    this.clients = this.client_groups
    console.log(42);
  },

  watch: {
    currentIncome: function (newV, oldV) {
      this.income = newV;

      oldV;
    },
  },
  methods: {
    editClient() {
      let data = {
        hour: this.income.hour,
        date_: this.income.date_,
        client_id: this.income.client_id,
        note: this.income.note,
      };
      AnketDataService.update(this.income.id, data)
        .then((response) => {
          console.log(response.data);
          this.$emit("edited");
          this.$notify({
            text: "Anket Düzenlendi",
            type: "success",
          });
        })
        .catch((e) => {
          console.log(e.response.data);
        });
    },
  },
};
</script>