<template>
  <div class="card" style="width: 30rem">
    <div class="card-header">{{ staff.name }}</div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item"><strong>ID:</strong> {{ staff.id }}</li>
      <li class="list-group-item">
        <strong>Kullanici Adi:</strong> {{ staff.username }}
      </li>
      <li class="list-group-item">
        <strong>e-Posta:</strong> {{ staff.email }}
      </li>
      <li class="list-group-item">
        <strong>Aktif mi?:</strong> {{ staff.is_active }}
      </li>
      <li class="list-group-item"><strong>Maas:</strong> {{ staff.salary }}</li>
      <li class="list-group-item">
        <strong>Diger Odemeler:</strong> {{ staff.other_benefits }}
      </li>
      <li class="list-group-item">
        <strong>Rolu:</strong> {{ staff["role.name"] }}
      </li>
      <li class="list-group-item">
        <strong>Birimi:</strong> {{ staff["branch.name"] }}
      </li>
    </ul>
    
  </div>
</template>
<script>
// import { mapState } from "vuex";

export default {
  name: "StaffBox",
  props: ["currentStaff"],
  // computed: mapState(["client_groups"]),
  data() {
    return {
      staff: {},
    };
  },
  created() {
    this.staff = this.currentStaff;
  },
  watch: {
    currentStaff: function (newV, oldV) {
      this.staff = newV;
      oldV;
    },
  },

  methods: {},
};
</script>