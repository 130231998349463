<template>
  <div class="card" style="width: 30rem">
    <div class="card-header">{{income.id}} #</div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item"><strong>Süre:</strong> {{income.hour}}</li>
      <li class="list-group-item"><strong>Tarih:</strong> {{income.date_}}</li>
      <li class="list-group-item"><strong>Müşteri:</strong> {{income['client_group.name']}}</li>
     
    </ul>
    <p class="card-text pl-3 ml-3">   &emsp;<strong>Açıklama:</strong><br/>
       &emsp;{{income.note}}
    </p>
  </div>
</template>
<script>
// import { mapState } from "vuex";

export default {
  name: "IncomeBox",
  props: ["currentIncome"],
  // computed: mapState(["client_groups"]),
  data() {
    return {
      income: {},
    };
  },
  created() {
    this.income = this.currentIncome;
  },
  watch: {
    currentIncome: function (newV, oldV) {
      this.income = newV;
      oldV;
    },
  },

  methods: {},
};
</script>