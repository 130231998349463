import http from "../http-common";
import store from '../store'

class StaffDataService {
    
  getAll() {
    return http.get("/users",
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  get(id) {
    return http.get(`/user/${id}`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
  whoAmI(){
    return http.get('/wai',
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
  adminMi(){
     http.get('/wai',
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    ).then(response=> {
      if(response.data.role=="Admin")
        return true
      return false
    });
  }

  create(data) {
    return http.post("/register", data, 
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  update(id, data) {
    return http.put(`/user/${id}`, data,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  delete(id) {
    return http.delete(`/user/${id}/`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} });
  }

  getTasks(id){
    return http.get(`/user/tasks/${id}`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} });
  }

  myId(){
    return http.get('/wai',
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} })
  }

  changePassword(id, data) {
    return http.put(`/change-pass/${id}`, data,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

 
}

export default new StaffDataService();