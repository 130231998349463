<template>
  <TopNav />
  <SideBar />
  <main class="ml-12 pl-3">
    <div class="row mt-5 pt-5">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">İş Yönetimi</a></li>
          <li class="breadcrumb-item active" aria-current="page">Gelirler</li>
        </ol>
      </nav>
      <br />
    </div>
     <br />
    <div class="row">
     
      <table id="datatable" class="table table-striped table-bordered pt-3 mt-3">
        <thead>
          <tr>
            <th>ID</th>
            <th>Başlık</th>
            <th>Tutar</th>
            <th>Period</th>
            <th>Türü</th>
            <th>Müşteri</th>
            <th>Oluşturulma Zamanı</th>
          </tr>
        </thead>
        <tbody>
          <tr
            :class="{ active: index == currentIndex }"
            v-for="(income, index) in this.incomes"
            :key="index"
            @click="clicked(income, index)"
          >
            <td>{{ income.id }}</td>
            <td>{{ income.name }}</td>
            <td>{{ income.amount }}</td>
            <td>{{ income["period.period"] }}</td>
            <td>{{ income["income_type.name"] }}</td>
            <td>{{ income["client.name"] }}</td>
            <td>{{ income.created_at }}</td>
          </tr>
        </tbody>
      </table>
      <IncomeList
        v-if="showIt"
        :currentIncome="currentIncome"
        @refreshSent="retrieveIncomes"
      />
      <div class="card" v-if="!showIt">
        <h5 class="card-title">Yeni Gelir</h5>
        <div class="card-body">
          <IncomeNew @newAdded="retrieveIncomes" />
        </div>
      </div>
    </div>
  </main>
</template>
 
<script>
// import IncomeDataService from "../services/IncomeDataService";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
import { mapState } from "vuex";
import IncomeList from "@/components/incomes/IncomeList";
import IncomeNew from "@/components/incomes/IncomeNew";
import SideBar from "@/components/SideBar";
import TopNav from "@/components/TopNav";

export default {
  name: "Income",
  computed: mapState(["access_token", "api_url"]),
  mounted() {
    const requestParams = {
      method: "GET",
      headers: { Authorization: `Bearer ` + this.access_token },
    };
    fetch(this.api_url+"incomes", requestParams)
      .then((response) => response.json())
      .then((data) => {
        this.incomes = data;
        console.log(this.incomes);
        setTimeout(() => {
          $("#datatable").DataTable({
            language: {
              url: "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Turkish.json",
            },
            lengthMenu: [
              [10, 25, 50, -1],
              [10, 25, 50, "Hepsi"],
            ],
            pageLength: 10,
          });
        });
      });
  },
  data: function () {
    return {
      incomes: [],
      currentIndex: -1,
      currentIncome: null,
      showIt: false,
    };
  },
  components: {
    IncomeList,
    IncomeNew,
    SideBar,
    TopNav,
  },
  methods: {
    clicked(income, index) {
      console.log(income.name);
      this.currentIncome = income;
      this.currentIndex = income ? index : -1;
      this.showIt = true;
    },
    retrieveIncomes() {
      const requestParams = {
        method: "GET",
        headers: { Authorization: `Bearer ` + this.access_token },
      };
      fetch(this.api_url+"incomes", requestParams)
        .then((response) => response.json())
        .then((data) => {
          this.incomes = data;
          console.log(this.incomes);
        });
    },
  },
};
</script>
<style scoped>
@import "~datatables.net-dt/css/jquery.dataTables.min.css";
.active {
  background-color: azure !important;
}
th {
  background-color: lightblue;
  border-radius: 5px !important;
}
</style>