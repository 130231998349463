<template>
  <h3>{{currentClient.name}} Unvanli Musteriden saglanan gelirler</h3>
  
  
        <ag-grid-vue
          domLayout="autoHeight"
          class="ag-theme-bootstrap"
          suppressFieldDotNotation=true
          :columnDefs="columnDefs"
          :rowData="rowData"
          @rowClicked="onRowClicked"
        >
        </ag-grid-vue>
        <br/>
        
        
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue3";
import { reactive } from "@vue/reactivity";
import ClientDataService from "../../services/ClientDataService";

export default {
  name: "ClientIncome",
  props: ["currentClient"],
  data() {
    return {
      incomes: [],
      currentClientId: -1,
      
      columnDefs: [
        {
          headerName: "#id",
          field: "id",
          filter: true,
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Baslik",
          field: "name",
          filter: true,
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Periyod",
          field: "period.name",
          filter: true,
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Turu",
          field: "type.name",
          filter: true,
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Olusturulma",
          field: "created_at",
          filter: true,
          sortable: true,
          resizable: true,
        },
        
      ],
      rowData: reactive([
        // this.tutorials.values
      ]),
      showList: false,
    };
  },

  components: {
    AgGridVue,
  },

  methods: {
    retrieveIncomes() {
      ClientDataService.getIncomes(this.currentClientId).then((response) => {
        this.incomes = response.data;
        console.log(this.incomes)
        if(this.incomes)
        this.rowData = this.incomes
      });
    },
    onRowClicked(e) {
      this.currentSelection = e.data.id;
      this.showList = true;
    },
  },
  watch: {
    currentClient: function (newV, oldV) {
      this.currentClientId = newV.id;
      oldV;
      this.retrieveIncomes();
    },
  },
  created() {
    if (this.currentClient) {
      this.currentClientId = this.currentClient.id;
      this.retrieveIncomes();
    }
  },
};
</script>
<style >

@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-bootstrap.css";
.ag-theme-bootstrap {
   border: 1px solid red;
}
    
    .ag-header-cell {
        font-weight: bold;

        border-right: 1px solid lightblue;
    }


</style>
