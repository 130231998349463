<template>
  <div class="row">
    <div class="mb-3 col-auto" >
      <label for="end_date" class="form-label">İzin Tarihi</label>
      <input
        type="date"
        class="form-control"
        id="end_date"
        v-model="iz_date"
      />
      <br />
      <button class="btn btn-primary btn-block" @click="addANew">
        Ekle
      </button>
    </div>
  </div>
</template>
<script>

import SelfServices from "../../services/SelfServices";


export default {
  name: 'IzinNew',
  
  emits: ["newAdded"],
  data() {
    return {
     iz_date: null
    };
  },
 
  created() {
   console.log("created")
   
  },


  methods: {
    addANew() {
      SelfServices.newizin({iz_date:this.iz_date})
      .then(response => {
        console.log(response.data)
        this.$emit("newAdded")
        this.$notify({
          text: "Izin Eklendi",
          type: "success"
        })
      })
      .catch(e => {
        console.log(e.response.data)
        this.$notify({
          text: e.response.data.message,
          type: "error"
        });
      })
    },
  },
};
</script>