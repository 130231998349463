<template>
  <div class="row">
    <div class="mb-3 col-auto" v-if="expense">
      <label for="unvan" class="form-label">Başlık</label>
      <input
        type="text"
        class="form-control"
        id="unvan"
        v-model="expense.name"
      />
      <label for="description" class="form-label">Açıklama</label>
      <textarea class="form-control" id="description" rows="4" v-model="expense.description"></textarea>
      <label for="amount" class="form-label">Tutar</label>
      <input type="number" class="form-control" id="amount" v-model="expense.amount" />
      <label for="project" class="form-label">Dönem</label>
      <select v-model="expense.period_id" id="project" class="form-control form-select">
        <option
          v-for="project in project_periods"
          :key="project.id"
          :value="project.id"
        >
          {{ project["period"] }}
        </option>
      </select>
     
      <br />
      <button class="btn btn-primary btn-block" @click="editExpense">
        Duzenle
      </button>
    </div>
  </div>
</template>
<script>
//import SelfServices from "../../services/SelfServices";
import ExpenseDataService from "../../services/ExpenseDataService";
import ClientDataService from "../../services/ClientDataService";
import { mapState } from "vuex";

export default {
  name: 'ExpenseEdit',
  emits: ["edited"],
  props: ["currentExpense"],
  computed: mapState(["project_periods"]),
  data() {
    return {
      selected: -1,
      expense: this.currentExpense,
      projects: []
    };
  },
  created() {
    ClientDataService.getAll().then(response => {
        this.projects = response.data
      }).catch(e => {
        console.log(e.response.data);
          })
  },

  watch: {
    currentExpense: function (newV, oldV) {
      this.expense = newV;
      
      oldV;
    },
  },
  methods: {
    editExpense() {
      let data = {
        name:this.expense.name,
        description:this.expense.description,
        amount:this.expense.amount,
        period_id:this.expense.period_id,
        created_at:this.expense.created_at
      }
      ExpenseDataService.update(this.expense.id, data)
      .then(response => {
        console.log(response.data)
        this.$emit("edited")
        this.$notify({
          text: "Harcama Düzenlendi",
          type: "success",
        });
      }).catch(e => {
        console.log(e.response.data)
      })
    },
  },
};
</script>