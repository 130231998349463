<template>
<TopNav />
<SideBar />
  <main class="ml-12 pl-3">
  <div class="row mt-5 pt-5">
    <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">İş Yönetimi</a></li>
            <li class="breadcrumb-item active" aria-current="page">Giderler</li>
          </ol>
        </nav>
    <table id="datatable" class="table table-striped table-bordered mt-3 pt-3">
      <thead>
        <tr>
          <th>ID</th>
          <th>Başlık</th>
          <th>Tutar</th>

          <th>Dönem</th>
          <th>Oluşturulma Zamanı</th>
        </tr>
      </thead>
      <tbody>
        <tr
          :class="{ active: index == currentIndex }"
          v-for="(expense, index) in this.expenses"
          :key="index"
          @click="clicked(expense, index)"
        >
          <td>{{ expense.id }}</td>
          <td>{{ expense.name }}</td>
          <td>{{ expense.amount }}</td>
        
          <td>{{ expense['period.period'] }}</td>

          <td>{{ expense.created_at }}</td>
        </tr>
      </tbody>
    </table>
    <ExpenseList
      v-if="showIt"
      :currentExpense="currentExpense"
      @refreshSent="retrieveExpenses"
    />
    <div class="card" v-if="!showIt">
      <h5 class="card-title">Yeni Gider</h5>

      <div class="card-body">
        <ExpenseNew @newAdded="retrieveExpenses" />
      </div>
    </div>
  </div>
  </main>
</template>
 
<script>
// import IncomeDataService from "../services/IncomeDataService";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
import { mapState } from "vuex";
import ExpenseList from "@/components/expenses/ExpenseList";
import ExpenseNew from "@/components/expenses/ExpenseNew";
import SideBar from "@/components/SideBar";
import TopNav from "@/components/TopNav";


export default {
  name: "Expense",
  computed: mapState(["access_token", "api_url"]),
  mounted() {
    const requestParams = {
      method: "GET",
      headers: { Authorization: `Bearer ` + this.access_token },
    };
    fetch(this.api_url+"expenses", requestParams)
      .then((response) => response.json())
      .then((data) => {
        this.expenses = data;
        console.log(this.expenses);
        setTimeout(() => {
          $("#datatable").DataTable({
            language: {
              url: "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Turkish.json",
            },
            lengthMenu: [
              [10, 25, 50, -1],
              [10, 25, 50, "Hepsi"],
            ],
            pageLength: 10,
          });
        });
      });
  },
  data: function () {
    return {
      expenses: [],
      currentIndex: -1,
      currentExpense: null,
      showIt: false,
    };
  },
  components: {
    ExpenseList,
    ExpenseNew,
    TopNav,
    SideBar
  },
  methods: {
    clicked(expense, index) {
      console.log(expense.name);
      this.currentExpense = expense;
      this.currentIndex = expense ? index : -1;
      this.showIt = true;
    },
    retrieveExpenses() {
      const requestParams = {
        method: "GET",
        headers: { Authorization: `Bearer ` + this.access_token },
      };
      fetch(this.api_url+"expenses", requestParams)
        .then((response) => response.json())
        .then((data) => {
          this.expenses = data;
          console.log(this.expenses);
        });
    },
  },
};
</script>
<style scoped>
@import "~datatables.net-dt/css/jquery.dataTables.min.css";
.active {
  background-color: azure !important;
}
th {
  background-color: lightblue;
  border-radius: 5px !important;
}
</style>