<template>
  <TopNav />
  <SideBar />
  <main class="mt-5 pt-3">
    <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">İş Yönetimi</a></li>
            <li class="breadcrumb-item active" aria-current="page">Loglar</li>
          </ol>
        </nav>
    <div class="container-fluid" >
      <div class="row">
        <div class="col-md-8" >
          <h5>Mevcut Görevler</h5>
          <ag-grid-vue
            style="height: 800px;"
            class="ag-theme-bootstrap"
            suppressFieldDotNotation="true"
            pagination="true"
            :columnDefs="columnDefs"
            :rowData="rows"
            @rowClicked="onRowClicked"
          >
          </ag-grid-vue>
        </div>
        <div class="col-md-4">
          <br/>
          <br/>
          
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue3";
import { reactive } from "@vue/reactivity";
import { mapState } from "vuex";

import TopNav from '@/components/TopNav'
import SideBar from '@/components/SideBar'

export default {
  name: "AllTask",
  computed: mapState(["access_token", "api_url"]),

  data() {
    return {
      rows: reactive([]),
      columnDefs: [
        {
          headerName: "#id",
          field: "id",
          filter: true,
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Açıklama",
          field: "description",
          filter: true,
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Kullanıcı",
          field: "staff.username",
          filter: true,
          sortable: true,
          resizable: true,
        },
        
        
        {
          headerName: "Zamanı",
          field: "created_at",
          filter: "agDateColumnFilter",
          sortable: true,
          resizable: true,
        },
        
        
      ],
      currentTask: {},
      showIt: false,
    };
  },
  components: {
    AgGridVue,
    TopNav,
    SideBar
  },
  created() {
    const requestParams = {
      method: "GET",
      headers: { Authorization: `Bearer ` + this.access_token },
    };
    fetch(this.api_url+"logs", requestParams)
      .then((response) => response.json())
      .then((data) => {
        this.rows = data;
      });
  },
  methods: {
    onRowClicked(e) {
      this.currentTask = e.data;
      this.showIt = true;
      console.log(e.data);
    },
  },
};
</script>

<style scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-bootstrap.css";
</style>