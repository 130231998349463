<template>
<SideBar />
<TopNav />
  <main class="ml-2">
    
  <div class="row mt-5 pt-5">
    <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">İş Yönetimi</a></li>
            <li class="breadcrumb-item active" aria-current="page">Çalışanlar</li>
          </ol>
        </nav>
    <br/>
    <table id="datatable" class="table table-striped table-bordered mt-3 pt-3">
      <thead>
        <tr>
          <th>ID</th>
          <th>Kullanici Adi</th>
          <th>e-Posta</th>
          <th>Aktif mi?</th>
          <th v-if="is_admin">Maas</th>
          <th v-if="is_admin">Diger Odemeler</th>
          <th>Rolu</th>
          <th>Birimi</th>
        </tr>
      </thead>
      <tbody>
        <tr
          :class="{ active: index == currentIndex }"
          v-for="(staff, index) in this.staffs"
          :key="index"
          @click="clicked(staff, index)"
        >
          <td>{{ staff.id }}</td>
          <td>{{staff.username}}</td>
          
          <td>{{staff.email}}</td>
          <td>{{staff.is_active}}</td>

          <td v-if="is_admin">{{staff.salary}}</td>
          <td v-if="is_admin">{{staff.other_benefits}}</td>
          <td>{{staff['role.name']}}</td>
          <td>{{staff['branch.name']}}</td>
        </tr>
      </tbody>
    </table>
    <span v-if="is_admin">
      <StaffList
      v-if="showIt"
      :currentStaff="currentStaff"
      @refreshSent="retrieveStaffs"
      />
    </span>
    <span v-if="is_pm">
      <StaffPassword
      v-if="showIt"
      :currentStaff="currentStaff"
      @refreshSent="retrieveStaffs"
      />
    </span>
    <div class="card" v-if="!showIt">
      <span v-if="is_admin">

        <h5 class="card-title">Yeni Çalışan</h5>
  
        <div class="card-body">
          <StaffNew @newAdded="retrieveStaffs" />
        </div>
      </span>
    </div>
  </div>
  </main>
</template>
 
<script>
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
import { mapState } from "vuex";
import StaffList from "@/components/staffs/StaffList";
import StaffNew from "@/components/staffs/StaffNew";
import StaffPassword from "@/components/staffs/StaffPassword"
import SideBar from "@/components/SideBar.vue"
import TopNav from "@/components/TopNav.vue"
import StaffDataService from "../services/StaffDataService";

export default {
  name: "Staff",
  computed: mapState(["access_token", "api_url"]),
  mounted() {
    const requestParams = {
      method: "GET",
      headers: { Authorization: `Bearer ` + this.access_token },
    };
    fetch(this.api_url+"users", requestParams)
      .then((response) => response.json())
      .then((data) => {
        this.staffs = data;
        console.log(this.staffs);
        setTimeout(() => {
          $("#datatable").DataTable({
            language: {
              url: "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Turkish.json",
            },
            lengthMenu: [
              [10, 25, 50, -1],
              [10, 25, 50, "Hepsi"],
            ],
            pageLength: 10,
          });
        });
      });
  },
  created(){
    StaffDataService.whoAmI().then(
      response => {
        console.log(response.data.role)
        if(response.data.role=='Admin'){
          this.is_admin = true
        }
        if(response.data.role=='PM'){
          this.is_pm = true
        }
      }
    )
  },
  data: function () {
    return {
      staffs: [],
      currentIndex: -1,
      currentStaff: null,
      showIt: false,
      is_admin: false,
      is_pm: false
    };
  },
  components: {
    StaffList,
    StaffNew,
    SideBar,
    TopNav,
    StaffPassword
  },
  methods: {
    clicked(staff, index) {
      console.log(staff.username);
      this.currentStaff = staff;
      this.currentIndex = staff ? index : -1;
      
        this.showIt = true;
      
    },
    retrieveStaffs() {
      const requestParams = {
        method: "GET",
        headers: { Authorization: `Bearer ` + this.access_token },
      };
      fetch(this.api_url+"users", requestParams)
        .then((response) => response.json())
        .then((data) => {
          this.staffs = data;
          console.log(this.staffs);
        });
    },
  },
};
</script>
<style scoped>
@import "~datatables.net-dt/css/jquery.dataTables.min.css";
.active {
  background-color: azure !important;
}
th {
  background-color: lightblue;
  border-radius: 5px !important;
}
</style>