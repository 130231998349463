import http from "../http-common";
import store from '../store'

class ClientDataService {
    
  getGroup(){
        return http.get("/client_groups",
        { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
        )
  }
  getGroupa(){
    return http.get("/client_groupsa",
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    )
}
  getAll() {
    return http.get("/clients",
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
  getAlla() {
    return http.get("/clientsa",
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  get(id) {
    return http.get(`/client/${id}`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  create(data) {
    return http.post("/client", data, 
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  update(id, data) {
    return http.put(`/client/${id}`, data,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  delete(id) {
    return http.delete(`/client/${id}/`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} });
  }

  getProject(id){
    return http.get(`client/pr/${id}`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} });
  }

  getIncomes(id){
    return http.get(`client/inc/${id}`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} });
  }
 
}

export default new ClientDataService();