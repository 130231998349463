<template>
  <TopNav />
  <SideBar />
      <main class="ml-12 pl-3 mt-4 pt-4">
         <div class="row mt-2">
      <div class="col-md-4"><nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Kullanıcı Menüsü</a></li>
            <li class="breadcrumb-item active" aria-current="page">Arşivlenmiş Görevlerim </li>
          </ol>
        </nav></div>
      
    </div>
    <br />

    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-6">
        <h3>{{this.$route.params.id}}</h3>
      </div>
    </div>
    <br />

    <div class="row">
      <div class="col-md-12">
        <table
          id="datatable"
          class="table table-striped table-bordered mt-3 pt-3"
        >
          <thead>
            <tr>
              <th>ID</th>
              <th style="width:30%">Müşteri</th>
              <th>Başlık</th>
              <th>Başlangıç</th>
              <th>Bitiş</th>
             
              
              <th>Türü</th>
              <th>Dönemi</th>
              <th>Arşiv Tarihi</th>
              <th>Açıklama</th>
              <th>Geri Al</th>
              <!-- <th>Sil</th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              :class="{ active: index == currentIndex }"
              v-for="(task, index) in this.tasks"
              :key="index"
              
            >
            <td>{{task["id"]}}</td>
            <td>{{task["client.name"]}}</td>
            <td>{{task["name"]}}</td>
            <td>{{task["start_date"]}}</td>
            <td>{{task["end_date"]}}</td>
        
           
            <td>{{task["task_type.name"]}}</td>
            <td>{{task["task_period.period"]}}</td>
          <td>{{task["due_date"]}}</td>
          <td>
            <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" :title="task['description']">
              <button class="btn btn-success"><i class="bi bi-eye"></i></button>
            </span>
          </td>
          <td>
              <button class="btn btn-warning" @click="unarchive(task['id'])"><i class="bi bi-arrow-repeat"></i></button>
          </td>
          <!-- <td>
              <button class="btn btn-danger" @click="fakeDelete(task['id'])"><i class="bi bi-dash-circle"></i></button>
          </td> -->

              
            </tr>
          </tbody>
        </table>
      </div>
        <AskDelayNew :currentTask="currentTask" v-if="showIt"/>

 
   
    </div>
      </main>

</template>

<script>
// @ is an alias to /src
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
import { mapState } from "vuex";
import SideBar from "@/components/SideBar";
import TopNav from "@/components/TopNav";
import AskDelayNew from '@/components/delays/AskDelayNew'
import TaskDataService from "../services/TaskDataService";

export default {
  name: "ArchivedTasks",
  computed: mapState(["access_token", "api_url"]),
  mounted() {
    const requestParams = {
      method: "GET",
      headers: { Authorization: `Bearer ` + this.access_token },
    };
    fetch(this.api_url+"archived-tasks", requestParams)
      .then((response) => response.json())
      .then((data) => {
        this.tasks = data;
        console.log(this.tasks);
        setTimeout(() => {
          $("#datatable").DataTable({
            language: {
              url: "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Turkish.json",
            },
            lengthMenu: [
              [10, 25, 50, -1],
              [10, 25, 50, "Hepsi"],
            ],
            order: [[6, 'desc']],
            pageLength: 10,
          });
        });
      });
  },
  data: function () {
    return {
      tasks: [],
      currentIndex: -1,
      currentTask: null,
      showIt: false,
    };
  },
  components: {
   
    TopNav,
    SideBar,
    AskDelayNew
  },
  methods: {
    
    unarchive(taskid){
      TaskDataService.update(taskid, {status_id:3})
            .then(response => {
                console.log(response.data)
            })
      location.reload()

    },
    fakeDelete(taskid){
      TaskDataService.update(taskid, {is_active:false})
            .then(response => {
                console.log(response.data)
            })
      location.reload()

    },
    retrieveTasks() {
      const requestParams = {
        method: "GET",
        headers: { Authorization: `Bearer ` + this.access_token },
      };
      fetch(this.api_url+"archived-tasks", requestParams)
        .then((response) => response.json())
        .then((data) => {
          this.tasks = data;
          console.log(this.tasks);
        });
    },
  },
};
</script>
<style scoped>
@import "~datatables.net-dt/css/jquery.dataTables.min.css";
.active {
  background-color: azure !important;
}
th {
  background-color: lightblue;
  border-radius: 5px !important;
}
</style>