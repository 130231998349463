<template>
  <div class="row" v-if="task">
    <div class="mb-3 col-auto">
      <h5>#{{task.id}} No'lu Gorev</h5>
      <label for="unvan" class="form-label">Başlık</label>
      <input type="text" class="form-control" id="unvan" v-model="task.name" />
      <label for="description" class="form-label">Açıklama</label>
      <textarea
        class="form-control"
        id="description"
        rows="4"
        v-model="task.description"
      ></textarea>
      <!--<label for="start_date" class="form-label">Başlangıç</label>
      <input
        type="date"
        class="form-control"
        id="start_date"
        v-model="task.start_date"
      />
      <label for="end_date" class="form-label">Bitiş</label>
      <input
        type="date"
        class="form-control"
        id="end_date"
        v-model="task.end_date"
      />-->
      <label for="type" class="form-label">Türü</label>
      <select v-model="task.task_type_id" id="type" class="form-control form-select">
        <option
          v-for="t in project_types"
          :key="t.id"
          :value="t.id"
        >
          {{ t.name }}
        </option>
      </select>
      <label for="donem" class="form-label">Dönem</label>
      <select v-model="task.period" id="donem" class="form-control form-select">
        <option
          v-for="p in project_periods"
          :key="p.id"
          :value="p.id"
        >
          {{ p.period }}
        </option>
      </select>
      <label for="checkbox">Aktif mi?</label>
      <input type="checkbox" id="checkbox" v-model="task.is_active" />
      <label for="status" class="form-label">Durumu</label>
      <select
        v-model="task.status_id"
        id="status"
        class="form-control form-select"
      >
        <option value="2">Askıda</option>
        <option value="3">Yapılıyor</option>
        <option value="4">Bitti</option>
        <option value="7">Arşivlendi</option>
      </select>

      
      <label for="staff" class="form-label">Müşteri</label>
      <select v-model="task.client_id" id="staff" class="form-control">
        <option value="">Seçiniz</option>
        <option
          v-for="project in projects"
          :key="project.id"
          :value="project.id"
        >
          {{ project.name}}
        </option>
      </select>
      <br />
      <button class="btn btn-primary btn-block" @click="editClient">
        Duzenle
      </button>
    </div>
  </div>
</template>
<script>
import StaffDataService from "../../services/StaffDataService";
import TaskDataService from "../../services/TaskDataService";
import SelfServices from "../../services/SelfServices";
import ClientDataService from "../../services/ClientDataService";
// import datePicker from 'vue-bootstrap-datetimepicker';
// import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

import { mapState } from "vuex";

export default {
  name: 'TaskEdit',
  emits: ["edited"],
  props: ["currentTask"],
  computed: mapState(["task_status", "project_periods", "project_types"]),
  data() {
    return {
      selected: -1,
      task: this.currentTask,
      projects: [],
      options: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
        }

    };
  },
  created() {
    StaffDataService.getAll().then(response => {
        this.staffs = response.data
      }).catch(e => {
        console.log(e.response.data);
          })

    SelfServices.whoAmI().then(response => {
        this.staff_id = response.data.id
      }).catch(e => {
        console.log(e.response.data);
          })
    ClientDataService.getAll().then(response => {
        this.projects = response.data
      }).catch(e => {
        console.log(e.response.data);
          })
  },

  watch: {
    currentTask: function (newV, oldV) {
      this.task = newV;

      oldV;
    },
  },
  components:{
    // datePicker,
  },
  methods: {
    editClient() {
       if (this.task.project_id == "") {
        this.task.project_id = null
      }
      let data = {
        name:this.task.name,
        description:this.task.description,
        start_date:this.task.start_date,
        end_date:this.task.end_date,
        status_id:this.task.status_id,
        staff_id:this.task.staff_id,
        task_type_id:this.task.task_type_id,
        period:this.task.period,
        is_active:this.task.is_active,
        client_id:this.task.client_id

      }

      TaskDataService.update(this.task.id, data)
      .then(response => {
        console.log(response.data)
        this.$emit("edited")
        this.$notify({
          text: "Gorev Düzenlendi, Lütfen sayfayı yenileyin",
          type: "success",
        });
      }).catch(e => {
        console.log(e.response.data)
      })
    },
  },
};
</script>
<style scoped></style>