<template>
    <div class="card" style="width: 30rem">
      <div class="card-header">#{{ currentTask.id }} No'lu Erteleme Talebiniz</div>
    
      <p class="card-text pl-3 ml-3">
        &emsp;<strong>Açıklama:</strong><br />
        &emsp;{{ currentTask.body }}
      </p>
      <p class="card-text pl-3 ml-3">
        &emsp;<strong>cevap:</strong><br />
        &emsp;{{ currentTask.description }}
      </p>
    </div>
</template>
<script>
export default {
    name: 'AskDelayBox',
    props: ["currentTask", "currentClient"],
    
    mounted(){
        console.log(this.currentClient)
        this.client = this.currentClient 
        this.project = this.currentTask

    }
    
}
</script>