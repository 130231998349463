<template>
  <div class="row">
    <div class="mb-3 col-auto" v-if="staff">
      <label for="username" class="form-label">Kullanici Adi</label>
      <input
        type="text"
        class="form-control"
        id="username"
        v-model="staff.username"
      />

      <label for="email" class="form-label">e-Posta</label>
      <input type="text" class="form-control" id="email"  v-model="staff.email"/>
      <br/>
      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="staff.is_active">
      <label class="form-check-label" for="flexCheckDefault">
          Aktif mi?
      </label><br/><br/>

      <label for="salary" class="form-label">Maas</label>
      <input type="number" class="form-control" id="salary" v-model="staff.salary" />

      <label for="other_benefits" class="form-label">Diger Odemeler</label>
      <input type="number" class="form-control" id="other_benefits" v-model="staff.other_benefits" />

      <label for="role" class="form-label">Rolü</label>
      <select v-model="staff.role_id" id="role" class="form-control form-select">
        <option
          v-for="role in roles"
          :key="role.id"
          :value="role.id"
        >
          {{ role.name }}
        </option>
      </select>
      <label for="branch" class="form-label">Birimi</label>
      <select v-model="staff.branch_id" id="branch" class="form-control  form-select">
        <option
          v-for="branch in branches"
          :key="branch.id"
          :value="branch.id"
        >
          {{ branch.name }}
        </option>
      </select>
     
      <br />
      <button class="btn btn-primary btn-block form-control" @click="editClient">
        Duzenle
      </button>
    </div>
  </div>
</template>
<script>
import StaffDataService from "../../services/StaffDataService";

import { mapState } from "vuex";

export default {
  name: 'StaffEdit',
  emits: ["edited"],
  props: ["currentStaff"],
  computed: mapState(["roles", "branches"]),
  data() {
    return {
      selected: -1,
      staff: this.currentStaff,
    };
  },
  created() {
    console.log(this.roles)
    console.log(this.branches)
  },

  watch: {
    currentStaff: function (newV, oldV) {
      this.staff = newV;
      
      oldV;
    },
  },
  methods: {
    editClient() {
      let data = {
        username:this.staff.username,
        email:this.staff.email,
        is_active:this.staff.is_active,
        salary:this.staff.salary,
        other_benefits:this.staff.other_benefits,
        role_id:this.staff.role_id,
        branch_id:this.staff.branch_id
      }
      StaffDataService.update(this.staff.id, data)
      .then(response => {
        console.log(response.data)
        this.$emit("edited")
        this.$notify({
          text: "Kişi Düzenlendi",
          type: "success",
        });
      }).catch(e => {
        console.log(e.response.data)
      })
    },
  },
};
</script>