<template>
  <div class="row">
    <div class="mb-3 col-auto" >
      <label for="unvan" class="form-label">Başlık</label>
      <input
        type="text"
        class="form-control"
        id="unvan"
        v-model="v$.form.name.$model"
      />
      <label for="description" class="form-label">Açıklama</label>
      <textarea class="form-control" id="description" rows="4" v-model="v$.form.description.$model"></textarea>
      <label for="amount" class="form-label">Tutar</label>
      <input type="number" class="form-control" id="amount" v-model="v$.form.amount.$model" />
      <label for="type" class="form-label">Türü</label>
      <select v-model="v$.form.type_id.$model" id="type" class="form-control  form-select">
        <option
          v-for="type in project_types"
          :key="type.id"
          :value="type.id"
        >
          {{ type.name }}
        </option>
      </select>
      <label for="period" class="form-label">Periyod</label>
      <select v-model="v$.form.period_id.$model" id="period" class="form-control  form-select">
        <option
          v-for="period in this.project_periods"
          :key="period.id"
          :value="period.id"
        >
          {{ period.period }}
        </option>
      </select>
      <label for="client" class="form-label">Müşteri</label>
      <select v-model="v$.form.client_id.$model" id="client" class="form-control  form-select">
        <option
          v-for="client in clients"
          :key="client.id"
          :value="client.id"
        >
          {{ client.name }}
        </option>
      </select>
      <br />
      <button class="btn btn-primary btn-block" @click="addANew">
        Ekle
      </button>
    </div>
  </div>
</template>
<script>
import ClientDataService from "../../services/ClientDataService";
import IncomeDataService from "../../services/IncomeDataService";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapState } from "vuex";


export default {
  setup() {
    return { v$: useVuelidate() };
  },
  emits: ["newAdded"],
  computed: mapState(["project_periods", "project_types"]),
  data() {
    return {
      selected: -1,
      clients: [],
      form: {
        name: "",
        description: "",
        amount: "",
        type_id: "",
        period_id: "",
        client_id: ""
      },
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
          name_validation: {
            $validator: required,
            $message: "Bu alan gereklidir.",
          },
        },
        description: {
          required,
          name_validation: {
            $validator: required,
           
            $message: "Bu alan gereklidir",
          },
        },
        amount: {
          required,
          name_validation: {
            $validator: required,
           
            $message: "Bu alan gereklidir",
          },
        },
        type_id: {
          required,
          name_validation: {
            $validator: required,
           
            $message: "Bu alan gereklidir",
          },
        },
        period_id: {
          required,
          name_validation: {
            $validator: required,
            $message: "Bu alan gereklidir.",
          },
        },
        client_id: {
          required,
          name_validation: {
            $validator: required,
            $message: "Bu alan gereklidir.",
          },
        },
      },
    };
  },
  created() {
    ClientDataService.getAll().then(response => {
      this.clients = response.data
    }).catch(e => {
      console.log(e.response.data);
        })
    console.log(this.income_types)
  },
 

  methods: {
    addANew() {
      let data = {
        name: this.form.name,
        description: this.form.description,
        amount: this.form.amount,
        type_id: this.form.type_id,
        period_id: this.form.period_id,
        client_id: this.form.client_id
      }
      IncomeDataService.create(data)
      .then(response => {
        console.log(response.data)
        this.$emit("newAdded")
        this.$notify({
          text: "Gelir Eklendi",
          type: "success",
        });
      }).catch(e => {
        console.log(e.response.data)
      })
    },
  },
};
</script>