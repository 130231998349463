import http from "../http-common";
import store from '../store'

class AnketDataService {
    
  getAll() {
    return http.get("/cbankets",
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  get(id) {
    return http.get(`/cbanket/${id}`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  create(data) {
    return http.post("/cbanket", data, 
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  update(id, data) {
    return http.put(`/cbanket/${id}`, data,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  delete(id) {
    return http.delete(`/cbanket/${id}/`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} });
  }

 
}

export default new AnketDataService();