<template>


  <div class="row  ">
    <h5 class="text-center">{{this.$route.params.id}} No'lu proje Harcamaları</h5>
    <table id="datatable2" class="table table-striped table-bordered mt-3 pt-3">
      <thead>
        <tr>
          <th>ID</th>
          <th>Başlık</th>
          <th>Tutar</th>

          <th>Proje</th>
          <th>Proje No</th>
          <th>Oluşturulma Zamanı</th>
        </tr>
      </thead>
      <tbody>
        <tr
          :class="{ active: index == currentIndex }"
          v-for="(expense, index) in this.expenses"
          :key="index"
          @click="clicked(expense, index)"
        >
          <td>{{ expense.id }}</td>
          <td>{{ expense.name }}</td>
          <td>{{ expense.amount }}</td>
          <td>{{ expense['project.name'] }}</td>
          <td>{{ expense['project_id'] }}</td>
          <td>{{ expense.created_at }}</td>
        </tr>
      </tbody>
    </table>
   
   
  </div>
</template>
 
<script>
// import IncomeDataService from "../services/IncomeDataService";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
import { mapState } from "vuex";


export default {
  name: "ProjectExpenses",
  computed: mapState(["access_token", "api_url"]),
  mounted() {
    const requestParams = {
      method: "GET",
      headers: { Authorization: `Bearer ` + this.access_token },
    };
    fetch(this.api_url+"project-expense/"+this.$route.params.id, requestParams)
      .then((response) => response.json())
      .then((data) => {
        this.expenses = data;
        console.log(this.expenses);
        setTimeout(() => {
          $("#datatable2").DataTable({
            language: {
              url: "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Turkish.json",
            },
            lengthMenu: [
              [10, 25, 50, -1],
              [10, 25, 50, "Hepsi"],
            ],
            pageLength: 10,
          });
        });
      });
  },
  data: function () {
    return {
      expenses: [],
      currentIndex: -1,
      currentExpense: null,
      showIt: false,
    };
  },
  components: {
    
  },
  methods: {
    clicked(expense, index) {
      console.log(expense.name);
      this.currentExpense = expense;
      this.currentIndex = expense ? index : -1;
      this.showIt = true;
    },
    retrieveExpenses() {
      const requestParams = {
        method: "GET",
        headers: { Authorization: `Bearer ` + this.access_token },
      };
      fetch(this.api_url+"project-expense/"+this.$route.params.id, requestParams)
        .then((response) => response.json())
        .then((data) => {
          this.expenses = data;
          console.log(this.expenses);
        });
    },
  },
};
</script>
<style scoped>
@import "~datatables.net-dt/css/jquery.dataTables.min.css";
.active {
  background-color: azure !important;
}
th {
  background-color: lightblue;
  border-radius: 5px !important;
}
</style>