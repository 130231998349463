<template>
  <TopNav />
  <SideBar />
  <main class="mt-5 pt-3">
    <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">İş Yönetimi</a></li>
            <li class="breadcrumb-item active" aria-current="page">Tüm Görevler</li>
          </ol>
        </nav>
    <div class="container-fluid" >
      <div class="row">
        <div style="margin: 10px 0">
				<button v-on:click="onBtnExport()">CSV olarak indir.</button>
			</div>
        <div class="col-md-8" >
          <h5>Mevcut Görevler</h5>
          <ag-grid-vue
            style="height: 800px;"
            class="ag-theme-bootstrap"
            suppressFieldDotNotation="true"
            pagination="true"
            :columnDefs="columnDefs"
            :rowData="rows"
            :suppressExcelExport="true"
            @grid-ready="onGridReady"
            @rowClicked="onRowClicked"
          >
          </ag-grid-vue>
        </div>
        <div class="col-md-4">
          <br/>
          <br/>
          <TaskList :currentTask="currentTask" v-if="showIt" />
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue3";
import { reactive } from "@vue/reactivity";
import { mapState } from "vuex";
import TaskList from "@/components/tasks/TaskList";
import TopNav from '@/components/TopNav'
import SideBar from '@/components/SideBar'

export default {
  name: "AllTask",
  computed: mapState(["access_token", "api_url"]),

  data() {
    return {
      rows: reactive([]),
      columnDefs: [
        {
          headerName: "#id",
          field: "id",
          filter: true,
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Görevli",
          field: "staff.username",
          filter: true,
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Müsteri",
          field: "client.name",
          filter: true,
          sortable: true,
          resizable: true,
        },
        // {
        //   headerName: "Müsteri VKN",
        //   field: "client.vkn",
        //   filter: true,
        //   sortable: true,
        //   resizable: true,
        // },
        // {
        //   headerName: "Proje",
        //   field: "project.name",
        //   filter: true,
        //   sortable: true,
        //   resizable: true,
        // },
        {
          headerName: "Aktif mi?",
          field: "is_active",
          filter: true,
          sortable: true,
          resizable: true,
          // cellRenderer: (params) => {
          //     const route = {
          //       name: 'Project',
          //       params: { id: params.value }
          //     };

          //     const link = document.createElement("a");
          //     link.href = this.$router.resolve(route).href;
          //     link.innerText = params.value;
          //     link.addEventListener("click", e => {
          //       e.preventDefault();
          //       this.$router.push(route);
          //     });
          //     return link;
          // }
        },
        {
          headerName: "Başlık",
          field: "name",
          filter: true,
          sortable: true,
          resizable: true,
        },
        
        {
          headerName: "Başlangıç",
          field: "start_date",
          filter: "agDateColumnFilter",
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Bitiş",
          field: "end_date",
          filter: "agDateColumnFilter",
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Arşivlenme Tarihi",
          field: "due_date",
          filter: "agDateColumnFilter",
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Durumu",
          field: "status.name",
          filter: true,
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Türü",
          field: "task_type.name",
          filter: true,
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Dönemi",
          field: "task_period.period",
          filter: true,
          sortable: true,
          resizable: true,
        },
        
      ],
      gridApi: null,
      columnApi: null,
      currentTask: {},
      showIt: false,
    };
  },
  components: {
    AgGridVue,
    TaskList,
    TopNav,
    SideBar
  },
  created() {
    const requestParams = {
      method: "GET",
      headers: { Authorization: `Bearer ` + this.access_token },
    };
    fetch(this.api_url+"tasks", requestParams)
      .then((response) => response.json())
      .then((data) => {
        this.rows = data;
      });
  },
  methods: {
    onRowClicked(e) {
      this.currentTask = e.data;
      this.showIt = true;
      console.log(e.data);
    },
    onBtnExport() {
      this.gridApi.exportDataAsCsv();
    },
    
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
  },
};
</script>

<style scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-bootstrap.css";
</style>