<template>
  <div class="row">
    <div class="mb-3 col-auto" >
      <label for="username" class="form-label">Kullanici Adi</label>
      <input
        type="text"
        class="form-control"
        id="username"
        v-model="staff.username"
        disabled=true
      />

      <label for="password" class="form-label">Şifre</label>
      <input type="text" class="form-control" id="password"  v-model="password"/>
      <br/>
      
      <button class="btn btn-primary btn-block form-control" @click="editClient">
        Şifreyi Değiştir
      </button>
    </div>
  </div>
</template>
<script>
import StaffDataService from "../../services/StaffDataService";

import { mapState } from "vuex";

export default {
  name: 'StaffEdit',
  emits: ["edited"],
  props: ["currentStaff"],
  computed: mapState(["roles", "branches"]),
  data() {
    return {
      selected: -1,
      staff: this.currentStaff,
      password: null,
    };
  },
  created() {
    console.log(this.roles)
    console.log(this.branches)
  },

  watch: {
    currentStaff: function (newV, oldV) {
      this.staff = newV;
      
      oldV;
    },
  },
  methods: {
    editClient() {
      let data = {
        password:this.password      }
      StaffDataService.changePassword(this.staff.id, data)
      .then(response => {
        console.log(response.data)
        this.$emit("edited")
        this.$notify({
          text: "Şifre Değiştirildi",
          type: "success",
        });
      }).catch(e => {
        console.log(e.response.data)
      })
    },
  },
};
</script>