<template>
  <div class="card" style="width: 30rem">
    <div class="card-header">#{{ project.id }} No'lu Erteleme Talebi</div>

    <p class="card-text pl-3 ml-3">
      &emsp;<strong>Açıklama:</strong><br />
      &emsp;{{ project.body }}
    </p>
    <p class="card-text pl-3 ml-3">
      &emsp;<strong>cevap:</strong><br />
      &emsp;{{ project.approval_text }}
    </p>
    <label for="approval_text" class="form-label">Açıklama</label>
    <textarea
      class="form-control"
      id="approval_text"
      rows="4"
      v-model="project.approval_text"
    ></textarea>
    <label for="approved_date" class="form-label">Uygun Görülen Tarih</label>
      <input type="date" class="form-control" id="approved_date" v-model="approved_date" />
    <input
      class="form-check-input"
      type="checkbox"
      value=""
      id="flexCheckDefault"
      v-model="project.approved"
    />
    <label class="form-check-label" for="flexCheckDefault"> Onaylandı </label
    ><br />
    <button class="btn btn-primary btn-block" @click="editClient">
      Cevapla
    </button>
  </div>
</template>
<script>
import AskDelayService from "../../services/AskDelayService";

export default {
  name: "AskDelayBox",
  props: ["currentTask"],
  data(){
    return {
      approved_date: null,
    }
  },

  created() {
    console.log(this.currentClient);
    this.client = this.currentClient;
    this.project = this.currentTask;
    this.approved_date = this.currentTask['task.end_date']
  },
  methods: {
    editClient() {
      let data = {
        approved: this.project.approved,
        approval_text: this.project.approval_text,
        approved_date: this.approved_date
      };
      AskDelayService.update(this.project.id, data)
        .then((response) => {
          console.log(response.data);
          this.$notify({
          text: "Gorev Düzenlendi",
          type: "success",
          });
        })
        .catch((e) => {
          console.log(e.response.data);
          this.$notify({
          text: e.response.data,
          type: "warn",
        });
        });
    },
  },
  watch: {
    currentTask: function (newV, oldV) {
      this.project = newV;
      oldV;
    },
  },
};
</script>