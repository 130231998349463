<template>
  <TopNav />
  <SideBar />

  <main class="ml-12 pl-3">
    <div class="row mt-5 pt-4">
      <div class="col-md-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">İş Yönetimi</a></li>
            <li class="breadcrumb-item active" aria-current="page">Müşteriler</li>
          </ol>
        </nav>
      </div>
      <div class="col-md-6">
        
        <ClientGroup
          :selection="currentSelection"
          @clientGroupChanged="groupChanged"
        />
      </div>
    </div>

    <br />

    <div class="row">
      <div class="col-md-12">
        <table
          id="datatable"
          class="table table-striped table-bordered mt-3 pt-3"
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>VKN</th>
              <th>Unvan</th>
              <th>Vergi Dairesi</th>
              <th>Grup</th>
              <th>Sorumlu</th>
              <th>Aktif mi?</th>
            </tr>
          </thead>
          <tbody>
            <tr
              :class="{ active: index == currentIndex }"
              v-for="(client, index) in this.rowData"
              :key="index"
              @click="clicked(client, index)"
            >
              <td>{{ client.id }}</td>
              <td>{{ client.vkn }}</td>
              <td>{{ client.name }}</td>
              <td>{{ client.vd }}</td>

              <td>{{ client["group.name"] }}</td>
              <td>{{ client["staff.username"] }}</td>
              <td>{{client.active}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <ClientList
      v-if="showList"
      :clientID="currentSelection.id"
      @refreshSent="doSmethng"
    />
    <div class="card col-md-3" v-else>
      <div class="card-header">
        <h4>Yeni Müşteri</h4>
      </div>
      <div class="card-body">
        <ClientNew @newClientAdded="doSmethng" />
      </div>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src

import TopNav from "@/components/TopNav.vue";
import SideBar from "@/components/SideBar.vue";

import "jquery/dist/jquery.min.js";
import $ from "jquery";

import "datatables.net-dt/js/dataTables.dataTables";
import ClientList from "@/components/clients/ClientList.vue";
import ClientNew from "@/components/clients/ClientNew.vue";

import ClientGroup from "@/components/clients/ClientGroup.vue";
import ClientDataService from "../services/ClientDataService";
import { reactive } from "@vue/reactivity";
import { mapState } from "vuex";

export default {
  name: "Clients",
  computed: mapState(["access_token", "api_url"]),

  data() {
    return {
      clients: [],
      currentSelection: -1,
      renderGrid: true,
      rowData: reactive([
        // this.tutorials.values
      ]),
      showList: false,
    };
  },

  components: {
    TopNav,
    SideBar,
    ClientList,
    ClientGroup,
    ClientNew,
  },

  methods: {
    retrieveClients() {
      const requestParams = {
        method: "GET",
        headers: { Authorization: `Bearer ` + this.access_token },
      };
      fetch(this.api_url+"clientsa", requestParams)
        .then((response) => response.json())
        .then((data) => {
          this.clients = data;
          this.rowData = this.clients;
          console.log(this.clients);
        });
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderGrid = false;
      this.rowData = [];
      ClientDataService.getAlla().then((response) => {
        this.clients = response.data;
        this.rowData = this.clients;
      });
      this.$nextTick(() => {
        // Add the component back in

        this.renderGrid = true;
      });
    },
    onRowClicked(e) {
      this.currentSelection = e.data.id;
      this.showList = true;
    },
    clicked(client, index) {
      console.log(client.name);
      this.currentSelection = client;
      this.currentIndex = client ? index : -1;
      this.showList = true;
    },
    groupChanged(e) {
      if (e == -1) this.rowData = this.clients;
      else if (e == -2) {
        this.rowData = this.clients.filter((item) => item.group_id == null);
      } else {
        this.rowData = this.clients.filter((item) => item.group_id == e);
        console.log(this.rowData)
      }
      console.log(e);
      console.log("didi");
    },
    doSmethng() {
      this.forceRerender();
    },
  },

  mounted() {
    const requestParams = {
      method: "GET",
      headers: { Authorization: `Bearer ` + this.access_token },
    };
    fetch(this.api_url+"clientsa", requestParams)
      .then((response) => response.json())
      .then((data) => {
        this.clients = data;
        this.rowData = this.clients;
        console.log(this.clients);
        setTimeout(() => {
          $("#datatable").DataTable({
            language: {
              url: "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Turkish.json",
            },
            lengthMenu: [
              [10, 25, 50, -1],
              [10, 25, 50, "Hepsi"],
            ],
            pageLength: 10,
          });
        });
      });
  },
};
</script>

<style scoped>
@import "~datatables.net-dt/css/jquery.dataTables.min.css";
.active {
  background-color: azure !important;
}
th {
  background-color: lightblue;
  border-radius: 5px !important;
}
</style>