<template>
  <div class="row">
    <div class="mb-3 col-auto" v-if="client">
      <label for="unvan" class="form-label">Unvan</label>
      <input
        type="text"
        class="form-control"
        id="unvan"
        v-model="client.name"
      />
      <label for="vkn" class="form-label">VKN</label>
      <input type="text" class="form-control" id="vkn" v-model="client.vkn" />
      <label for="vd" class="form-label">Vergi Dairesi</label>
      <input type="text" class="form-control" id="vd" v-model="client.vd" />
      <label for="group" class="form-label">Musteri Grubu</label>
      <select v-model="selected" id="group" class="form-control">
        // eslint-disable-next-line vue/require-v-for-key
        <option :key="-1" :value="-1">Hicbiri</option>
        <option
          v-for="group in client_groups"
          :key="group.id"
          :value="group.id"
        >
          {{ group.name }}
        </option>
      </select>

      <label for="staff" class="form-label">Sorumlu</label>
      <select v-model="client.staffed" id="staff" class="form-control">
        <option
          v-for="staff in staffs"
          :key="staff.id"
          :value="staff.id"
        >
          {{ staff.username }}
        </option>
      </select>
      <br />
      <label for="checkbox" class="form-label">Aktif mi?_
        (Müşterinin Görünürlüğü) 	&nbsp;</label>
      <input type="checkbox" id="checkbox" class="form-check-input" v-model="client.active" /> <br/>
      <br />
      <button class="btn btn-primary btn-block" @click="editClient">
        Duzenle
      </button>
    </div>
  </div>
</template>
<script>
import ClientDataService from "../../services/ClientDataService";
import StaffDataService from "../../services/StaffDataService";
import { mapState } from "vuex";

export default {
  computed: mapState(["client_groups"]),
  emits: ["edited"],
  props: ["currentClient"],
  data() {
    return {
      selected: -1,
      client: this.currentClient,
      staffs: [],
    };
  },
  created() {
    StaffDataService.getAll().then(response => {
      this.staffs = response.data
    }).catch(e => {
      console.log(e.response.data);
        })


    if (this.client) {
      this.client = this.currentClient
      this.selected = this.client.group_id
    if(this.client.group_id) this.selected= this.client.group_id
    } else this.selected = -1
  },

  watch: {
    currentClient: function (newV, oldV) {
      this.client = newV;
      this.selected = this.client.group_id
      oldV;
    },
  },
  methods: {
    editClient() {
      if (this.client) {
       
        
        let data = {
          name: this.client.name,
          vkn: this.client.vkn,
          vd: this.client.vd,
          staffed: this.client.staffed,
          active: this.client.active
          
        }
        if(this.selected!=-1)data.group_id=this.selected
        console.log(data)

        ClientDataService.update(this.client.id, data)
        .then(response => {
          console.log(response.data)
          this.$notify({
          text: "Müşteri Düzenlendi",
          type: "success",
        });
          this.$emit("edited")
        })
        .catch(e => {
          console.log(e.response.data);
        })
      }
    },
  },
};
</script>