<template>
  <div class="row">
    <div class="mb-3 col-auto">
      <label for="unvan" class="form-label">Başlık</label>
      <input type="text" class="form-control" id="unvan" v-model="name" />
      <label for="description" class="form-label">Açıklama</label>
      <textarea
        class="form-control"
        id="description"
        rows="4"
        v-model="description"
      ></textarea>
      <label for="start_date" class="form-label">Başlangıç</label>
      <input
        type="date"
        class="form-control"
        id="start_date"
        v-model="start_date"
      />
      <label for="end_date" class="form-label">Bitiş</label>
      <input
        type="date"
        class="form-control"
        id="end_date"
        v-model="end_date"
      />
      <label for="type" class="form-label">Türü</label>
      <select v-model="task_type_id" id="type" class="form-control form-select">
        <option
          v-for="t in project_types"
          :key="t.id"
          :value="t.id"
        >
          {{ t.name }}
        </option>
      </select>
      <label for="donem" class="form-label">Dönem</label>
      <select v-model="period" id="donem" class="form-control form-select">
        <option
          v-for="p in project_periods"
          :key="p.id"
          :value="p.id"
        >
          {{ p.period }}
        </option>
      </select>
      <label for="status" class="form-label">Durumu</label>
      <select v-model="status_id" id="status" class="form-control form-select">

        <option value="2">Askıda</option>
        <option value="3">Yapılıyor</option>
        <option value="4">Bitti</option>
        <option value="7">Arşivlendi</option>
      </select>
      <label for="staff" class="form-label">Müşteri</label>
      <select v-model="client_id" id="staff" class="form-control">
        <option  value="">Seçiniz</option>

        <option v-for="project in projects" :key="project.id" :value="project.id">
          {{ project.name}}
        </option>
      </select>
      <label for="staff" class="form-label">Görevli</label>
      <select v-model="staff_id" id="staff" class="form-control">
        <option v-for="staff in staffs" :key="staff.id" :value="staff.id">
          {{ staff.username }}
        </option>
      </select>
      <br />
      <button class="btn btn-primary btn-block" @click="editClient">
        Ekle
      </button>
    </div>
  </div>
</template>
<script>
import StaffDataService from "../../services/StaffDataService";
import TaskDataService from "../../services/TaskDataService";
import ClientDataService from "../../services/ClientDataService";

import { mapState } from "vuex";

export default {
  name: 'TaskEdit',
  emits: ["edited"],
  computed: mapState(["task_status", "project_periods", "project_types"]),
  data() {
    return {
      selected: -1,
      staffs: [],
      projects: [],
      name:null,
      description:null,
      start_date:null,
      end_date:null,
      client_id:null,
      status_id:null,
      staff_id:null,
      period:null,
      task_type_id:null,
      
    };
  },
  created() {
    StaffDataService.getAll().then(response => {
      this.staffs = response.data
    }).catch(e => {
      console.log(e.response.data);
        })
    
      ClientDataService.getAll().then(response => {
        this.projects = response.data
          }).catch(e => {
            console.log(e.response.data);
            })
  },

  
  methods: {
    editClient() {
      let data = {
        name:this.name,
        description:this.description,
        start_date:this.start_date,
        end_date:this.end_date,
        status_id:this.status_id,
        staff_id:this.staff_id,
        task_type_id:this.task_type_id,
        period:this.period,
        client_id:this.client_id,
        adminin:true
      }

      TaskDataService.create(data)
      .then(response => {
        console.log(response.data)
        this.$emit("edited")
        this.$notify({
          text: "Gorev Eklendi",
          type: "success"
        });
      }).catch(e => {
        console.log(e.response.data)
      })
    },
  },
};
</script>