<template>
  <TopNav />
  <SideBar />
  <main class="mt-5 pt-3">
    <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">İş Yönetimi</a></li>
            <li class="breadcrumb-item"><a href="#">Projeler</a></li>
            <li class="breadcrumb-item active" aria-current="page">#{{project.id}} No'lu Proje</li>
          </ol>
        </nav>
    <div class="row">
    <div class="col-12">
      <div class="responsive-tabs">
        <ul class="nav nav-tabs" role="tablist">
           <li class="nav-item">
            <a
              id="tab-A"
              href="#pane-A"
              class="nav-link active"
              data-bs-toggle="tab"
              role="tab"
              ><i class="bi bi-eye"> Görüntüle</i
            ></a>
          </li>
          
          <li class="nav-item">
            <a
              id="tab-B"
              href="#pane-B"
              class="nav-link "
              data-bs-toggle="tab"
              role="tab"
              ><i class="bi bi-pencil"> Düzenle</i
            ></a>
          </li>
          <li class="nav-item">
            <a
              id="tab-C"
              href="#pane-C"
              class="nav-link"
              data-bs-toggle="tab"
              role="tab"
              ><i class="bi bi-list-check"> Görevler</i
            ></a>
          </li>
          <li class="nav-item">
            <a
              id="tab-D"
              href="#pane-D"
              class="nav-link"
              data-bs-toggle="tab"
              role="tab"
              ><i class="bi bi-cash-stack"> Harcamalar</i
            ></a>
          </li>
          
        </ul>

        <div id="content" class="tab-content" role="tablist">
          <div
            id="pane-A"
            class="card tab-pane fade show active"
            role="tabpanel"
            aria-labelledby="tab-A"
          >
            <div class="card-header" role="tab" id="heading-A">
              <div class="card-body">
                  <ProjectBox :currentProject="this.project" :currentClient="this.client" />
              </div>
            </div>
          </div>
          <div
            id="pane-B"
            class="card tab-pane fade show "
            role="tabpanel"
            aria-labelledby="tab-B"
          >
            <div class="card-header" role="tab" id="heading-B">
              <div class="card-body">
              <ProjectEdit :currentProject="this.project"  />
              </div>
            </div>
          </div>
          <div
            id="pane-C"
            class="card tab-pane fade"
            role="tabpanel"
            aria-labelledby="tab-C"
          >
            <div class="card-header" role="tab" id="heading-C">
              <div class="card-body">
                <ProjectTask :currentProject="project"  :project_id="project_id"/>
              </div>
            </div>
          </div>

          <div
            id="pane-D"
            class="card tab-pane fade"
            role="tabpanel"
            aria-labelledby="tab-D"
          >
            <div class="card-header" role="tab" id="heading-D">
              <div class="card-body">
                <ProjectExpenses />
              </div>
            </div>
          </div>
         
         
        </div>
      </div>
    </div>
  </div>
  </main>
</template>

<script>
// @ is an alias to /src

import TopNav from "@/components/TopNav.vue";
import SideBar from "@/components/SideBar.vue";
import ProjectBox from "@/components/projects/ProjectBox.vue";
import ProjectEdit from "@/components/projects/ProjectEdit.vue";
import ProjectTask from "@/components/projects/ProjectTask.vue";
import ProjectExpenses from "@/components/projects/ProjectExpenses.vue";

import ProjectDataService from "@/services/ProjectDataService";
import ClientDataService from "@/services/ClientDataService";

export default {
  name: "Projects",
  components: {
    TopNav,
    SideBar,
    ProjectBox,
    ProjectEdit,
    ProjectTask,
    ProjectExpenses
  },
  data() {
    return {
      project: {},
      client: {},
      project_id: this.$route.params.id
    };
  },
  created() {
    ProjectDataService.get(this.$route.params.id).then((response) => {
      this.project = response.data;
      ClientDataService.get(this.project.client_id).then((response_) => {
        this.client = response_.data;
      });
    });
  },
};
</script>


