<template>
  <TopNav />
  <SideBar />
      <main class="ml-12 pl-3 mt-4 pt-4">

    <div class="row mt-2">
      <div class="col-md-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">İş Yönetimi</a></li>
            <li class="breadcrumb-item active" aria-current="page">Erteleme Talepleri</li>
          </ol>
        </nav>
      </div>
      
    </div>
    <br />

    <div class="row">
      <div class="col-md-12">
        <table
          id="datatable"
          class="table table-striped table-bordered mt-3 pt-3"
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>Başlık</th>
              <th>Başlangıç</th>
              <th>Bitiş</th>
              <th style="width:30%">Müşteri</th>
              
              <th>Durumu</th>
              <th>Türü</th>
              <th>Dönemi</th>
              <th>Onay Durumu</th>
              <th>İstenilen Tarih</th>
              
              <th>Açıklama</th>
            </tr>
          </thead>
          <tbody>
            <tr
              :class="{ active: index == currentIndex }"
              v-for="(task, index) in this.tasks"
              :key="index"
              @click="clicked(task, index)"
            >
            <td>{{task["id"]}}</td>
              <td>{{task["task.name"]}}</td>
              <td>{{task["task.start_date"]}}</td>
              <td>{{task["task.end_date"]}}</td>
              <td>{{task["task.client.name"]}}</td> 
              <td>{{task["task.status.name"]}}</td>
              <td>{{task["task.task_type.name"]}}</td>
              <td>{{task["task.task_period.period"]}}</td>
              <td>{{task["approved"]}}</td>
              <td>{{task["desired_date"]}}</td>
              <td>
                <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" :title="task.description">
                  <button class="btn btn-success"><i class="bi bi-eye"></i></button>
                </span>
              </td>    
            </tr>
          </tbody>
        </table>
      </div>
        

 
   <AskDelayEdit :currentTask="currentTask" v-if="showIt"/>
    </div>
      </main>

</template>

<script>
// @ is an alias to /src
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
import { mapState } from "vuex";
import SideBar from "@/components/SideBar";
import TopNav from "@/components/TopNav";
import AskDelayEdit from '@/components/delays/AskDelayEdit'
export default {
  name: "AskDelayAdmin",
  computed: mapState(["access_token", "api_url"]),
  mounted() {
    const requestParams = {
      method: "GET",
      headers: { Authorization: `Bearer ` + this.access_token },
    };
    fetch(this.api_url+"delays", requestParams)
      .then((response) => response.json())
      .then((data) => {
        this.tasks = data;
        console.log(this.tasks);
        setTimeout(() => {
          $("#datatable").DataTable({
            language: {
              url: "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Turkish.json",
            },
            lengthMenu: [
              [10, 25, 50, -1],
              [10, 25, 50, "Hepsi"],
            ],
            pageLength: 10,
          });
        });
      });
  },
  data: function () {
    return {
      tasks: [],
      currentIndex: -1,
      currentTask: null,
      showIt: false,
    };
  },
  components: {
   
    TopNav,
    SideBar,
    AskDelayEdit
  },
  methods: {
    clicked(task, index) {
      console.log(task.id);
      this.currentTask = task;
      this.currentIndex = task ? index : -1;
      this.showIt = true;
    },
    retrieveTasks() {
      const requestParams = {
        method: "GET",
        headers: { Authorization: `Bearer ` + this.access_token },
      };
      fetch(this.api_url+"delays", requestParams)
        .then((response) => response.json())
        .then((data) => {
          this.tasks = data;
          console.log(this.tasks);
        });
    },
  },
};
</script>
<style scoped>
@import "~datatables.net-dt/css/jquery.dataTables.min.css";
.active {
  background-color: azure !important;
}
th {
  background-color: lightblue;
  border-radius: 5px !important;
}
</style>

