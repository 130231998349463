<template>
  <TopNav />
  <SideBar />

    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-6">
        <h5>{{this.$route.params.id}} No'lu proje görevleri</h5>
      </div>
    </div>
   

    <div class="row">
      <div class="col-md-12">
        <table
          id="datatable"
          class="table table-striped table-bordered mt-3 pt-3"
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>Görevli</th>
              <th>Proje</th>
              <th>Başlık</th>
              <th>Başlangıç</th>
              <th>Bitiş</th>
              <th>Teslim Tarihi</th>
              <th>Durumu</th>
            </tr>
          </thead>
          <tbody>
            <tr
              :class="{ active: index == currentIndex }"
              v-for="(task, index) in this.tasks"
              :key="index"
              @click="clicked(task, index)"
            >
              <td>{{task["id"]}}</td>
          <td>{{task["staff.username"]}}</td>
          <td>{{task["project.name"]}}</td>
          <td>{{task["name"]}}</td>
          <td>{{task["start_date"]}}</td>
          <td>{{task["end_date"]}}</td>
          <td>{{task["due_date"]}}</td>
          <td>{{task["status.name"]}}</td>
             

              
            </tr>
          </tbody>
        </table>
      </div>
    

    <TaskList
      v-if="showIt"
      :currentTask="currentTask"
      @refreshSent="retrieveTasks"
    />
   <div class="card" v-if="!showIt">
      <h5 class="card-title">Yeni Görev</h5>

      <div class="card-body">
        <TaskNew @edited="retrieveTasks" />
      </div>
    </div>
    </div>
</template>

<script>
// @ is an alias to /src
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
import { mapState } from "vuex";
import TaskList from "@/components/tasks/TaskList";
import TaskNew from "@/components/tasks/TaskNew";
import SideBar from "@/components/SideBar";
import TopNav from "@/components/TopNav";


export default {
  name: "Task",
  computed: mapState(["access_token", "api_url"]),
  mounted() {
    const requestParams = {
      method: "GET",
      headers: { Authorization: `Bearer ` + this.access_token },
    };
    fetch(this.api_url+"project-task/"+this.$route.params.id, requestParams)
      .then((response) => response.json())
      .then((data) => {
        this.tasks = data;
        console.log(this.tasks);
        setTimeout(() => {
          $("#datatable").DataTable({
            language: {
              url: "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Turkish.json",
            },
            lengthMenu: [
              [10, 25, 50, -1],
              [10, 25, 50, "Hepsi"],
            ],
            pageLength: 10,
          });
        });
      });
  },
  data: function () {
    return {
      tasks: [],
      currentIndex: -1,
      currentTask: null,
      showIt: false,
    };
  },
  components: {
    TaskList,
    TaskNew,
    TopNav,
    SideBar
  },
  methods: {
    clicked(task, index) {
      console.log(task.name);
      this.currentTask = task;
      this.currentIndex = task ? index : -1;
      this.showIt = true;
    },
    retrieveTasks() {
      const requestParams = {
        method: "GET",
        headers: { Authorization: `Bearer ` + this.access_token },
      };
      fetch(this.api_url+"project-task/"+this.$route.params.id, requestParams)
        .then((response) => response.json())
        .then((data) => {
          this.tasks = data;
          console.log(this.tasks);
        });
    },
  },
};
</script>
<style scoped>
@import "~datatables.net-dt/css/jquery.dataTables.min.css";
.active {
  background-color: azure !important;
}
th {
  background-color: lightblue;
  border-radius: 5px !important;
}
</style>