<template>
  <div class="row">
    <div class="mb-3 col-auto" >
     
      
      <label for="hour" class="form-label">Süre (Saat)</label>
      <input type="number" class="form-control" id="hour" v-model="v$.form.hour.$model" />
      <label for="date_" class="form-label">Tarihi</label>
      <input
        type="date"
        class="form-control"
        id="date_"
        v-model="v$.form.date_.$model"
      />
    
      <label for="client_id" class="form-label">Müşteri</label>
      <select v-model="v$.form.client_id.$model" id="client" class="form-control  form-select">
        <option
          v-for="client in clients"
          :key="client.id"
          :value="client.id"
        >
          {{ client.name }}
        </option>
      </select>
      <label for="unvan" class="form-label">note</label>
      <input
        type="text"
        class="form-control"
        id="note"
        v-model="v$.form.note.$model"
      />
      <br />
      <button class="btn btn-primary btn-block" @click="addANew">
        Ekle
      </button>
    </div>
  </div>
</template>
<script>
import AnketDataService from "../../services/AnketDataService";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapState } from "vuex";


export default {
  setup() {
    return { v$: useVuelidate() };
  },
  emits: ["newAdded"],
  computed: mapState(["project_periods", "project_types", "client_groups"]),
  data() {
    return {
      selected: -1,
      clients: this.client_groups,
      form: {
        hour: "",
        date_: "",
        client_id: "",
        note: ""
      },
    };
  },
  validations() {
    return {
      form: {
        hour: {
          required,
          name_validation: {
            $validator: required,
            $message: "Bu alan gereklidir.",
          },
        },
        date_: {
          required,
          name_validation: {
            $validator: required,
           
            $message: "Bu alan gereklidir",
          },
        },
        client_id: {
          required,
          name_validation: {
            $validator: required,
           
            $message: "Bu alan gereklidir",
          },
        },
        note: {
          required,
          name_validation: {
            $validator: required,
           
            $message: "Bu alan gereklidir",
          },
        },
        
      },
    };
  },
  created() {
    this.clients = this.client_groups
    console.log(42)
  },
 

  methods: {
    addANew() {
      let data = {
        hour: this.form.hour,
        date_: this.form.date_,
        client_id: this.form.client_id,
        note: this.form.note,
      }
      AnketDataService.create(data)
      .then(response => {
        console.log(response.data)
        this.$emit("newAdded")
        this.$notify({
          text: "Gelir Eklendi",
          type: "success",
        });
      }).catch(e => {
        console.log(e.response.data)
      })
    },
  },
};
</script>