import { createStore } from "vuex";
import axios from "axios";
axios.defaults.baseURL = "https://cumhurbilenymm.com/api/";
//axios.defaults.baseURL = "http://localhost:5000/api/";
//axios.defaults.baseURL = "http://192.227.249.28/api/";
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";

export default createStore({
  state: {
    api_url: "https://cumhurbilenymm.com/api/",
    //api_url: "http://localhost:5000/api/",
    //api_url: "http://192.227.249.28/api/",
    access_token: null,
    refresh_token: null,
    client_groups: [],
    income_periods: [],
    income_types: [],
    project_types: [],
    project_periods: [],
    branches: [],
    roles: [],
    task_status: [],
    unit: [],
    urgency: [],
  },
  mutations: {
    getToken(state, access_token) {
      state.access_token = access_token;
      localStorage.setItem("access_token", JSON.stringify(state.access_token));
    },
    getRefresh(state, refresh_token) {
      state.refresh_token = refresh_token;
      localStorage.setItem(
        "refresh_token",
        JSON.stringify(state.refresh_token)
      );
    },
    getClientGroup(state, responseData) {
      state.client_groups = responseData;
      localStorage.setItem(
        "client_groups",
        JSON.stringify(state.client_groups)
      );
    },

    getIncomePeriod(state, responseData) {
      state.income_periods = responseData;
      localStorage.setItem(
        "income_periods",
        JSON.stringify(state.income_periods)
      );
    },
    getIncomeType(state, responseData) {
      state.income_types = responseData;
      localStorage.setItem("income_types", JSON.stringify(state.income_types));
    },
    getProjectType(state, responseData) {
      state.project_types = responseData;
      localStorage.setItem(
        "project_types",
        JSON.stringify(state.project_types)
      );
    },
    getProjectPeriod(state, responseData) {
      state.project_periods = responseData;
      localStorage.setItem(
        "project_periods",
        JSON.stringify(state.project_periods)
      );
    },
    getBranch(state, responseData) {
      state.branches = responseData;
      localStorage.setItem("branches", JSON.stringify(state.branches));
    },
    getRole(state, responseData) {
      state.roles = responseData;
      localStorage.setItem("roles", JSON.stringify(state.roles));
    },
    getTaskStatus(state, responseData) {
      state.task_status = responseData;
      localStorage.setItem("task_status", JSON.stringify(state.task_status));
    },
    getUnit(state, responseData) {
      state.unit = responseData;
      localStorage.setItem("unit", JSON.stringify(state.unit));
    },
    getUrgency(state, responseData) {
      state.urgency = responseData;
      localStorage.setItem("urgency", JSON.stringify(state.urgency));
    },
  },
  actions: {
    getToken({ commit }, access_token) {
      commit("getToken", access_token);
    },
    getRefresh({ commit }, refresh_token) {
      commit("getRefresh", refresh_token);
    },
    getClientGroup({ commit }) {
      axios
        .get("/client_groups", {
          headers: { Authorization: `Bearer ` + this.state.access_token },
        })
        .then((response) => commit("getClientGroup", response.data));
    },
 
    getIncomePeriod({ commit }) {
      axios
        .get("/income_periods", {
          headers: { Authorization: `Bearer ` + this.state.access_token },
        })
        .then((response) => commit("getIncomePeriod", response.data));
    },
    getIncomeType({ commit }) {
      axios
        .get("/income_types", {
          headers: { Authorization: `Bearer ` + this.state.access_token },
        })
        .then((response) => commit("getIncomeType", response.data));
    },
    getProjectType({ commit }) {
      axios
        .get("/project_types", {
          headers: { Authorization: `Bearer ` + this.state.access_token },
        })
        .then((response) => commit("getProjectType", response.data));
    },
    getProjectPeriod({ commit }) {
      axios
        .get("/project_periods", {
          headers: { Authorization: `Bearer ` + this.state.access_token },
        })
        .then((response) => commit("getProjectPeriod", response.data));
    },
    getBranch({ commit }) {
      axios
        .get("/branches", {
          headers: { Authorization: `Bearer ` + this.state.access_token },
        })
        .then((response) => commit("getBranch", response.data));
    },
    getRole({ commit }) {
      axios
        .get("/roles", {
          headers: { Authorization: `Bearer ` + this.state.access_token },
        })
        .then((response) => commit("getRole", response.data));
    },
    getTaskStatus({ commit }) {
      axios
        .get("/task_statuses", {
          headers: { Authorization: `Bearer ` + this.state.access_token },
        })
        .then((response) => commit("getTaskStatus", response.data));
    },
    getUnit({ commit }) {
      axios
        .get("/units", {
          headers: { Authorization: `Bearer ` + this.state.access_token },
        })
        .then((response) => commit("getUnit", response.data));
    },
    getUrgency({ commit }) {
      axios
        .get("/urgencies", {
          headers: { Authorization: `Bearer ` + this.state.access_token },
        })
        .then((response) => commit("getUrgency", response.data));
    },

    setToken({ commit }) {
      if (localStorage.getItem("access_token")) {
        commit("getToken", JSON.parse(localStorage.getItem("access_token")));
      }
    },
    setRefresh({ commit }) {
      if (localStorage.getItem("refresh_token")) {
        commit("getRefresh", JSON.parse(localStorage.getItem("refresh_token")));
      }
    },
    setClientGroup({ commit }) {
      if (localStorage.getItem("client_groups")) {
        commit(
          "getClientGroup",
          JSON.parse(localStorage.getItem("client_groups"))
        );
      }
    },
    setIncomePeriod({ commit }) {
      if (localStorage.getItem("income_periods")) {
        commit(
          "getIncomePeriod",
          JSON.parse(localStorage.getItem("income_periods"))
        );
      }
    },
    setIncomeType({ commit }) {
      if (localStorage.getItem("income_types")) {
        commit(
          "getIncomeType",
          JSON.parse(localStorage.getItem("income_types"))
        );
      }
    },
    setProjectType({ commit }) {
      if (localStorage.getItem("project_types")) {
        commit(
          "getProjectType",
          JSON.parse(localStorage.getItem("project_types"))
        );
      }
    },
    setProjectPeriod({ commit }) {
      if (localStorage.getItem("project_periods")) {
        commit(
          "getProjectPeriod",
          JSON.parse(localStorage.getItem("project_periods"))
        );
      }
    },
    setBranch({ commit }) {
      if (localStorage.getItem("branches")) {
        commit("getBranch", JSON.parse(localStorage.getItem("branches")));
      }
    },
    setRole({ commit }) {
      if (localStorage.getItem("roles")) {
        commit("getRole", JSON.parse(localStorage.getItem("roles")));
      }
    },
    setTaskStatus({ commit }) {
      if (localStorage.getItem("task_status")) {
        commit(
          "getTaskStatus",
          JSON.parse(localStorage.getItem("task_status"))
        );
      }
    },
    setUnit({ commit }) {
      if (localStorage.getItem("unit")) {
        commit("getUnit", JSON.parse(localStorage.getItem("unit")));
      }
    },
    setUrgency({ commit }) {
      if (localStorage.getItem("urgency")) {
        commit("getUrgency", JSON.parse(localStorage.getItem("urgency")));
      }
    },

    logoutAction({ commit }) {
      axios
        .post("/logout", {}, {
          headers: { Authorization: `Bearer ` + this.state.access_token },
        })
        .then((response) => console.log(response.data));
      commit("getToken", null)
      commit("getRefresh", null)
      commit("getClientGroup", [])
      commit("getIncomePeriod", [])
      commit("getIncomeType", [])
      commit("getProjectType", [])
      commit("getProjectPeriod", [])
      commit("getBranch", [])
      commit("getRole", [])
      commit("getTaskStatus", [])
      commit("getUnit", [])
      commit("getUrgency", [])
      
    },
  },
  modules: {},
});
