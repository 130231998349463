<template>
  <div class="row">
    <div class="col-12">
      <h4 v-if="currentClient">{{ currentClient.name }} Bilgileri</h4>
      <div class="responsive-tabs">
        <ul class="nav nav-tabs" role="tablist">
          
          <li class="nav-item">
            <a
              id="tab-B"
              href="#pane-B"
              class="nav-link active"
              data-bs-toggle="tab"
              role="tab"
              ><i class="bi bi-pencil"> Düzenle</i
            ></a>
          </li>
          <li class="nav-item">
            <a
              id="tab-C"
              href="#pane-C"
              class="nav-link"
              data-bs-toggle="tab"
              role="tab"
              ><i class="bi bi-plus-circle"> Ekle</i
            ></a>
          </li>
          <li class="nav-item">
            <a
              id="tab-D"
              href="#pane-D"
              class="nav-link"
              data-bs-toggle="tab"
              role="tab"
              ><i class="bi bi-cash-coin" width="32" height="32"> Gelirler</i
            > </a>
          </li>
          <!--<li class="nav-item">
            <a
              id="tab-E"
              href="#pane-E"
              class="nav-link"
              data-bs-toggle="tab"
              role="tab"
              ><i class="bi bi-stack" width="32" height="32"> Projeler</i
            ></a>
          </li>-->
        </ul>

        <div id="content" class="tab-content" role="tablist">
          
          <div
            id="pane-B"
            class="card tab-pane fade show active"
            role="tabpanel"
            aria-labelledby="tab-B"
          >
            <div class="card-header" role="tab" id="heading-B">
              <div class="card-body">
                <ClientEdit :currentClient="currentClient" @edited="$emit('refreshSent')"/>
              </div>
            </div>
          </div>
          <div
            id="pane-C"
            class="card tab-pane fade"
            role="tabpanel"
            aria-labelledby="tab-C"
          >
            <div class="card-header" role="tab" id="heading-C">
              <div class="card-body">
                <ClientNew @newClientAdded="this.$emit('refreshSent')"/>
              </div>
            </div>
          </div>
          <div
            id="pane-D"
            class="card tab-pane fade show"
            role="tabpanel"
            aria-labelledby="tab-D"
          >
            <div class="card-header" role="tab" id="heading-D">
              <div class="card-body">
                <ClientIncome :currentClient="currentClient"/>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClientDataService from "../../services/ClientDataService"
// import ClientBox from "./ClientBox.vue";
import ClientEdit from "./ClientEdit.vue";
import ClientIncome from "./ClientIncome.vue";
import ClientNew from "./ClientNew.vue";
//import ClientProject from "./ClientProject.vue";

export default {
  name: "ClientList",
  props: ["clientID"],
  emits:["refreshSent",],
  data() {
    return {
      currentClient: null,
    };
  },
  created() {
    ClientDataService.get(this.clientID).then((response) => {
      this.currentClient = response.data
    })
  },
  watch: {
    clientID: function (newV, oldV) {
      ClientDataService.get(newV).then((response) => {
        this.currentClient = response.data
        oldV
      })
    },
  },

  components: {
    // ClientBox,
    ClientEdit,
    ClientIncome,
    ClientNew,
    //ClientProject,
  },
};
</script>

<style scoped>
.bi-dash-circle {
  color: crimson !important;
  size: 40px !important;
}
</style>