<template>
    <div class="card" style="width: 30rem">
      <div class="card-header">#{{ currentTask.id }}|{{ currentTask.name }}</div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <strong>Başlangıç:</strong> {{ currentTask.start_date }}
        </li>
        <li class="list-group-item">
          <strong>Bitiş:</strong> {{ currentTask.end_date }}
        </li>
       
        
        <li class="list-group-item">
          <strong>Müşteri:</strong> {{ currentTask["client.name"] }}
        </li>
        <li class="list-group-item">
          <strong>Durumu:</strong> {{ currentTask["status.name"] }}
        </li>
        <li class="list-group-item">
          <strong>Dönemi:</strong> {{ currentTask["task_period.period"] }}
        </li>
        <li class="list-group-item">
          <strong>Türü:</strong> {{ currentTask["task_type.name"] }}
        </li>
      </ul>
      <p class="card-text pl-3 ml-3">
        &emsp;<strong>Açıklama:</strong>
        &emsp;{{ currentTask.description }}
      </p>
    </div>
</template>
<script>
export default {
    name: 'TaskBox',
    props: ["currentTask"],
    
    mounted(){
        this.task = this.currentTask

    }
    
}
</script>