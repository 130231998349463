<template>
  <div class="row">
    <div class="mb-3 col-auto" >
      <label for="username" class="form-label">Kullanici Adi</label>
      <input
        type="text"
        class="form-control"
        id="username"
        v-model="v$.form.username.$model"
      />

      <label for="email" class="form-label">e-Posta</label>
      <input type="text" class="form-control" id="email"  v-model="v$.form.email.$model"/>

      <label for="password" class="form-label">Şifre</label>
      <input type="text" class="form-control" id="password"  v-model="v$.form.password.$model"/>     

      <label for="salary" class="form-label">Maas</label>
      <input type="number" class="form-control" id="salary" v-model="v$.form.salary.$model" />

      <label for="other_benefits" class="form-label">Diger Odemeler</label>
      <input type="number" class="form-control" id="other_benefits" v-model="v$.form.other_benefits.$model" />

      <label for="role" class="form-label">Rolü</label>
      <select v-model="v$.form.role_id.$model" id="role" class="form-control form-select">
        <option
          v-for="role in roles"
          :key="role.id"
          :value="role.id"
        >
          {{ role.name }}
        </option>
      </select>
      <label for="branch" class="form-label">Birimi</label>
      <select v-model="v$.form.branch_id.$model" id="branch" class="form-control  form-select">
        <option
          v-for="branch in branches"
          :key="branch.id"
          :value="branch.id"
        >
          {{ branch.name }}
        </option>
      </select>
     
      <br />
      <button class="btn btn-primary btn-block form-control" @click="addANew">
        Ekle
      </button>
    </div>
  </div>
</template><script>
import StaffDataService from "../../services/StaffDataService";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapState } from "vuex";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  emits: ["newAdded"],
  computed: mapState(["roles", "branches"]),
  data() {
    return {
      selected: -1,
      clients: [],
      form: {
          username: "",
          email: "",
          password: "",
          salary: "",
          other_benefits: "",
          role_id: "",
          branch_id: "",
      },
    };
  },
  validations() {
    return {
      form: {
        username: {
          required,
          name_validation: {
            $validator: required,
            $message: "Bu alan gereklidir.",
          },
        },
        email: {
          required,
          name_validation: {
            $validator: required,
           
            $message: "Bu alan gereklidir",
          },
        },
         password: {
          required,
          name_validation: {
            $validator: required,
           
            $message: "Bu alan gereklidir",
          },
        },
        salary: {
          required,
          name_validation: {
            $validator: required,
           
            $message: "Bu alan gereklidir",
          },
        },
        other_benefits: {
          required,
          name_validation: {
            $validator: required,
           
            $message: "Bu alan gereklidir",
          },
        },
        role_id: {
          required,
          name_validation: {
            $validator: required,
            $message: "Bu alan gereklidir.",
          },
        },
        branch_id: {
          required,
          name_validation: {
            $validator: required,
            $message: "Bu alan gereklidir.",
          },
        },
      },
    };
  },
  created() {
    
  },


  methods: {
    addANew() {
      let data = {
        username: this.form.username,
        email: this.form.email,
        password: this.form.password,
        salary: this.form.salary,
        other_benefits: this.form.other_benefits,
        role_id: this.form.role_id,
        branch_id: this.form.branch_id
      }
      console.log(data)
      StaffDataService.create(data)
      .then(response => {
        console.log(response.data)
        this.$emit("newAdded")
        this.$notify({
          text: "Kişi Eklendi",
          type: "success",
        });
      }).catch(e => {
        console.log(e.response.data)
      })
    },
  },
};
</script>