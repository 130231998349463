<template>
  <div class="row">
    <div class="mb-3 col-auto" >
      <label for="unvan" class="form-label">Başlık</label>
      <input
        type="text"
        class="form-control"
        id="unvan"
        v-model="v$.form.name.$model"
      />
      <label for="description" class="form-label">Açıklama</label>
      <textarea class="form-control" id="description" rows="4" v-model="v$.form.description.$model"></textarea>
      <label for="amount" class="form-label">Tutar</label>
      <input type="number" class="form-control" id="amount" v-model="v$.form.amount.$model" />
      
      <label for="project" class="form-label">Dönem</label>
      <select v-model="v$.form.period_id.$model" id="project" class="form-control  form-select">
        <option
          v-for="project in project_periods"
          :key="project.id"
          :value="project.id"
        >
          {{ project["period"] }}
        </option>
      </select>
      
      <br />
      <button class="btn btn-primary btn-block" @click="addANew">
        Ekle
      </button>
    </div>
  </div>
</template>
<script>
//import ProjectDataService from "../../services/ProjectDataService";
import ExpenseDataService from "../../services/ExpenseDataService";
//import SelfServices from "../../services/SelfServices";
import ClientDataService from "../../services/ClientDataService";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapState } from "vuex";

export default {
  name: 'IncomeNew',
  setup() {
    return { v$: useVuelidate() };
  },
  computed: mapState([ "project_periods"]),
  emits: ["newAdded"],
  data() {
    return {
      selected: -1,
      projects: [],
      form: {
        name: "",
        description: "",
        amount: "",
        period_id: "",
      },
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
          name_validation: {
            $validator: required,
            $message: "Bu alan gereklidir.",
          },
        },
        description: {
          required,
          name_validation: {
            $validator: required,
           
            $message: "Bu alan gereklidir",
          },
        },
        amount: {
          required,
          name_validation: {
            $validator: required,
           
            $message: "Bu alan gereklidir",
          },
        },
        period_id: {
          required,
          name_validation: {
            $validator: required,
           
            $message: "Bu alan gereklidir",
          },
        },
      },
    };
  },
  created() {
    ClientDataService.getAll().then(response => {
        this.projects = response.data
      }).catch(e => {
        console.log(e.response.data);
          })
  },


  methods: {
    addANew() {
      let data = {
        name: this.form.name,
        description: this.form.description,
        amount: this.form.amount,
        period_id: this.form.period_id
       
      }
      ExpenseDataService.create(data)
      .then(response => {
        console.log(response.data)
        this.$emit("newAdded")
        this.$notify({
          text: "Harcama Eklendi",
          type: "success",
        });
      }).catch(e => {
        console.log(e.response.data)
      })
    },
  },
};
</script>