import http from "../http-common";
import store from '../store'

class ProjectDataService {
    
  getAll() {
    return http.get("/tasks",
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
  
  getMines() {
    return http.get("/my-tasks",
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
  archived() {
    return http.get("/archived-tasks",
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
  get(id) {
    return http.get(`/task/${id}`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
  getProjectTasks(id) {
    return http.get(`/project-task/${id}`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  create(data) {
    return http.post("/task", data, 
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  update(id, data) {
    return http.put(`/task/${id}`, data,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  delete(id) {
    return http.delete(`/task/${id}/`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} });
  }

 
}

export default new ProjectDataService();