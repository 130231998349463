<template>
  <div class="mr-5 row gy-2 gx-3 align-items-center">
    <div class="mb-0 pb-0 col-auto">
      <label class="form-label select-label">&nbsp; Müşteri Grubu</label>
    </div>

    <div class="col-auto" v-show="!editItem && !addNew">
    <div>
      <select
        v-model="selected"
        class="form-control form-select"
        @change="getSelectedItem($event)"
      >
        // eslint-disable-next-line vue/require-v-for-key
        <option :key="-1" :value="-1">Hepsi</option>
        <option :key="-2" :value="-2">Grubu olmayanlar</option>
        <option v-for="(client) in groupList" :key="client.id" :value="client.id">
          {{client.id}}:{{ client.name }}
        </option>
      </select>
    </div>
    </div>
    <div v-if="showButton" class="col-auto">
      <i class="bi bi-pencil" style="color: orange" @click="showEditGroup"></i>
      &nbsp;
      <i
        class="bi bi-plus-circle"
        style="color: darkblue"
        @click="showAddGroup"
      ></i>
    </div>
    <div v-if="addNew" class="col-auto">
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          placeholder="Yeni Musteri Grubu"
         
          v-model="newGroup"
        />
        <span class="input-group-text" id="basic-addon2">
        <i class="bi bi-check-circle" @click="addNewGroup"></i>&nbsp;
          <i class="bi bi-x-circle" @click="cancelAdd"></i
        ></span>
      </div>
    </div>
    <div v-show="editItem" class="col-auto">
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          :placeholder="selected + ' nolu Grup'"
          v-model="editGroup"
        />
        <span class="input-group-text" id="basic-addon1">
        <i class="bi bi-check-circle" @click="editingGroup"></i>&nbsp;
          <i class="bi bi-x-circle" @click="cancelEdit"></i
        ></span>
      </div>
    </div>
  </div>
</template>
<script>

import { mapActions, mapState } from "vuex";
import axios from "axios";


export default {
  props: {
    selection: Number,
  },
  computed: mapState(["client_groups", "access_token", "api_url"]),
  emits: ["clientGroupChanged"],
  data() {
    return {
      groupList: this.client_groups,
      selected: null,
      showButton: true,
      addNew: false,
      editItem: false,
      newGroup: "",
      editGroup: "",
    };
  },

  created() {
    this.selected = this.selection;
    this.groupList = this.client_groups;
    axios.defaults.baseURL = this.api_url;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
  },
  
  watch: {
    client_groups: function (newV, oldV) {
      this.groupList = newV;
      oldV;
    },
  },
  methods: {
    getSelectedItem() {
      this.$emit("clientGroupChanged", this.selected);
    },
    showAddGroup() {
      this.addNew = true;
      this.showButton = false;
    },

    addNewGroup() {
      if (this.newGroup.trim() != "") {
        axios.defaults.headers.post["Content-Type"] =
          "application/json;charset=utf-8";
        axios.post(
          "/client_group/" + this.newGroup,
          {},
          { headers: { Authorization: `Bearer ` + this.access_token } }
        ).then(response => this.groupList.push(response.data));
        
        this.addNew = false;
        this.showButton = true;

        this.$notify({
          text: "Müşteri Grubu Eklendi",
          type: "success",
        });
        this.$store.dispatch("getClientGroup");
      } else {
        this.$notify({
          text: "Boş Bırakamazsınız!",
          type: "warn",
        });
      }
      this.newGroup = "";
    },

    cancelAdd() {
      this.addNew = false;
      this.showButton = true;
      this.$notify({
        text: "İşlem iptal edildi",
        type: "info",
      });
    },
    editingGroup() {
      if (
        this.editGroup.trim() != "" &&
        this.selected != -1 &&
        this.selected != -2
      ) {
        axios.defaults.headers.post["Content-Type"] =
          "application/json;charset=utf-8";
        axios.put(
          "/edit-group/" + this.selected + "/" + this.editGroup,
          {},
          { headers: { Authorization: `Bearer ` + this.access_token } }
        );
        axios.put(
          "/edit-group/" + this.selected + "/" + this.editGroup,
          {},
          { headers: { Authorization: `Bearer ` + this.access_token } }
        );
        
        this.editItem = false;
        this.showButton = true;
        this.$notify({
          text: "Müşteri Grubu Duzenlendi",
          type: "success",
        });

        this.$store.dispatch("getClientGroup");
        this.$store.dispatch("getClientGroup");
        this.$store.dispatch("getClientGroup");

      } else {
        this.$notify({
          text: "Boş Bırakamazsınız!",
          type: "warn",
        });
      }
      this.$store.dispatch("getClientGroup");
      this.groupList = this.client_groups
    },

    showEditGroup() {
      if (this.selected != -1 && this.selected != -2) {
        this.editItem = true;
        this.showButton = false;
        let q = this.groupList.find((item) => item.id == this.selected);
        this.editGroup = q.name;
      } else {
        this.$notify({
          text: "Secim yapiniz",
          type: "warn",
        });
      }
    },

    cancelEdit() {
      this.editItem = false;
      this.showButton = true;
      this.$notify({
        text: "İşlem iptal edildi",
        type: "info",
      });
    },
    ...mapActions({
      getClientGroup: "getClientGroup",
    }),
  },
};
</script>
