<template>
<!-- top navigation bar -->
    <nav class="zirto navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#sidebar"
          aria-controls="offcanvasExample"
        >
          <span class="navbar-toggler-icon" data-bs-target="#sidebar"></span>
        </button>
        <a
          class="navbar-brand me-auto ms-lg-0 ms-3 text-uppercase fw-bold"
          href="#"
          >CİB YMM</a
        >
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#topNavBar"
          aria-controls="topNavBar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="topNavBar">
         
        
        </div>
         
      </div>
      <div class="d-flex align-items-center">
          
            <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">{{myName}}</button>
          
            &nbsp;&nbsp;
        
         </div>

    </nav>
    <!-- top navigation bar -->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Yeni Şifre</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3 mt-3">
         
          <input type="text" class="form-control" id="password" placeholder="Yeni şifreniz" name="password" v-model="password" pattern="^([0-9a-zA-Z]+)*$">
        </div>
       
        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="submit" >Değiştir</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import SelfServices from '@/services/SelfServices'
export default {
  name: 'TopNav',
  props: {
    msg: String
  },
  data(){ 
    return{
      myName: '',
      password: ''
    }
  },
  created(){
    SelfServices.whoAmI().then((response) => {
      this.myName=response.data.username
      console.log(response.data.id)
    });

  },
  methods: {
    submit(){
      let data = {
        password:this.password      }
      SelfServices.myPassword(data)
      .then(response => {
        console.log(response.data)
        this.$emit("edited")
        this.$notify({
          text: "Şifre Değiştirildi",
          type: "success",
        });
      }).catch(e => {
        console.log(e.response.data)
      })
    },
  }
}
</script>


