<template>
  <div class="row">
    <div class="mb-3 col-auto">
      <label for="unvan" class="form-label">Unvan</label>
      <div
        class="input-errors"
        v-for="(error, index) of v$.form.name.$errors"
        :key="index"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
      <input
        type="text"
        class="form-control"
        id="name"
        v-model="v$.form.name.$model"
      />
      <label for="vkn" class="form-label">VKN</label>

      <input
        type="text"
        class="form-control"
        id="vkn"
        v-model="v$.form.vkn.$model"
      />
      <label for="vd" class="form-label">Vergi Dairesi</label>
      <input
        type="text"
        class="form-control"
        id="vd"
        v-model="v$.form.vd.$model"
      />
      <label for="group" class="form-label">Musteri Grubu</label>
      <select v-model="selected" id="group" class="form-control">
        // eslint-disable-next-line vue/require-v-for-key
        <option :key="-1" :value="-1">Hicbiri</option>
        <option
          v-for="group in client_groups"
          :key="group.id"
          :value="group.id"
        >
          {{ group.name }}
        </option>
      </select>
      <br />
      <button class="btn btn-primary btn-block form-control" @click="addClient">
        Ekle
      </button>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { required, minLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import ClientDataService from "../../services/ClientDataService";

// export function validName(name) {
//   let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
//   if (validNamePattern.test(name)){
//     return true;
//   }
//   return false;
// }

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  emits: ["newClientAdded"],

  computed: mapState(["client_groups"]),

  data() {
    return {
      selected: -1,
      form: {
        name: "",
        vkn: "",
        vd: "",
      },
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
          name_validation: {
            $validator: required,
            $message: "Bu alan gereklidir.",
          },
        },
        vkn: {
          required,
          name_validation: {
            $validator: required,
            min: minLength(10),
            $message: "Bu alan gereklidir en az 10 karakter olacak",
          },
        },
        vd: {
          required,
          name_validation: {
            $validator: required,
            $message: "Bu alan gereklidir.",
          },
        },
      },
    };
  },
  created() {},

  watch: {
    currentClient: function (newV, oldV) {
      this.client = newV;
      oldV;
    },
  },
  methods: {
    addClient() {
      console.log("adding..." + this.selected);
      let data = {
        name: this.form.name,
        vkn: this.form.vkn,
        vd: this.form.vd,
      };
      console.log("adding..." + this.selected);
      if (this.selected != -1) data.group_id = parseInt(this.selected);
      console.log(data);

      ClientDataService.create(data)
        .then((response) => {
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e.response.data);
        });
      this.$emit("newClientAdded");
      this.$notify({
          text: "Müşteri Eklendi",
          type: "success",
        });
    },
  },
};
</script>