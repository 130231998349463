import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import Notifications from '@kyvg/vue3-notification'
import "./assets/style.css"
import axios from 'axios'
import VueAxios from "vue-axios"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

createApp(App)
    .use(store)
    .use(router)
    .use(VueAxios, axios)
    .use(Notifications)
    .mount('#app')
import "bootstrap/dist/js/bootstrap.js"