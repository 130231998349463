<template>
  <div class="row">
    <div class="mb-3 col-auto" v-if="income">
      <label for="unvan" class="form-label">Başlık</label>
      <input
        type="text"
        class="form-control"
        id="unvan"
        v-model="income.name"
      />
      <label for="description" class="form-label">Açıklama</label>
      <textarea
        class="form-control"
        id="description"
        rows="4"
        v-model="income.description"
      ></textarea>
      <label for="amount" class="form-label">Tutar</label>
      <input
        type="number"
        class="form-control"
        id="amount"
        v-model="income.amount"
      />
      <label for="type" class="form-label">Türü</label>
      <select
        v-model="income.type_id"
        id="type"
        class="form-control form-select"
      >
        <option v-for="type in project_types" :key="type.id" :value="type.id">
          {{ type.name }}
        </option>
      </select>
      <label for="period" class="form-label">Periyod</label>
      <select
        v-model="income.period_id"
        id="period"
        class="form-control form-select"
      >
        <option
          v-for="period in this.project_periods"
          :key="period.id"
          :value="period.id"
        >
          {{ period.period }}
        </option>
      </select>
      <label for="client" class="form-label">Müşteri</label>
      <select v-model="income.client_id" id="client" class="form-control">
        <option v-for="client in clients" :key="client.id" :value="client.id">
          {{ client.name }}
        </option>
      </select>
      <br />
      <button class="btn btn-primary btn-block" @click="editClient">
        Duzenle
      </button>
    </div>
  </div>
</template>
<script>
import ClientDataService from "../../services/ClientDataService";
import IncomeDataService from "../../services/IncomeDataService";

import { mapState } from "vuex";

export default {
  name: "IncomeEdit",
  emits: ["edited"],
  props: ["currentIncome"],
  computed: mapState(["project_periods", "project_types"]),
  data() {
    return {
      selected: -1,
      income: this.currentIncome,
      clients: [],
    };
  },
  created() {
    ClientDataService.getAll()
      .then((response) => {
        this.clients = response.data;
      })
      .catch((e) => {
        console.log(e.response.data);
      });
    console.log(this.income_types);
  },

  watch: {
    currentIncome: function (newV, oldV) {
      this.income = newV;

      oldV;
    },
  },
  methods: {
    editClient() {
      let data = {
        name: this.income.name,
        description: this.income.description,
        amount: this.income.amount,
        type_id: this.income.type_id,
        period_id: this.income.period_id,
        client_id: this.income.client_id,
        created_at: this.income.created_at,
      };
      IncomeDataService.update(this.income.id, data)
        .then((response) => {
          console.log(response.data);
          this.$emit("edited");
          this.$notify({
            text: "Gelir Düzenlendi",
            type: "success",
          });
        })
        .catch((e) => {
          console.log(e.response.data);
        });
    },
  },
};
</script>