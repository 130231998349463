<template>
<div>

</div>
<router-view/>
<notifications position="bottom right" />
</template>

<script>
import axios from 'axios'

import {mapState} from 'vuex'



export default {
   computed: mapState([
        'access_token',
        'refresh_token',
        'api_url']),
  
  created() {
    this.$store.dispatch('setToken')
    this.$store.dispatch('setRefresh')
    axios.defaults.baseURL = this.api_url
    axios.defaults.headers.post['Content-Type'] = 'application/jsoncharset=utf-8'
      
      if(this.access_token) axios.get("/wai", { headers: {"Authorization" : `Bearer `+ this.access_token} })
      .catch((error)=>{if(error.response.status==401)
        {
          this.$store.dispatch('getToken', null)
        }
      })
  
  // expected output: ReferenceError: nonExistentFunction is not defined
  // Note - error messages will vary depending on browser


 
      this.$store.dispatch('setClientGroup')
      this.$store.dispatch('setIncomePeriod')
      this.$store.dispatch('setIncomeType')
      this.$store.dispatch('setProjectType')
      this.$store.dispatch('setProjectPeriod')
      this.$store.dispatch('setBranch')
      this.$store.dispatch('setRole')
      this.$store.dispatch('setTaskStatus')
      this.$store.dispatch('setUnit')
      this.$store.dispatch('setUrgency')       
      },
      
    
}
</script>