<template>
  <div class="card" style="width: 30rem" v-if="expense">
    <div class="card-header">{{expense.name}}</div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item"><strong>Tutar:</strong> {{expense.amount}}</li>
      
      <li class="list-group-item"><strong>Dönemi:</strong> {{expense['period.period']}}</li>
      <li class="list-group-item"><strong>Oluşturulma Zamanı:</strong> {{expense['created_at']}}</li>
     
    </ul>
    <p class="card-text pl-3 ml-3">   &emsp;<strong>Açıklama:</strong><br/>
       &emsp;{{expense.description}}
    </p>
  </div>
</template>
<script>
// import { mapState } from "vuex";

export default {
  name: "ExpenseBox",
  props: ["currentExpense"],
  // computed: mapState(["client_groups"]),
  data() {
    return {
      expense: {},
    };
  },
  created() {
    this.expense = this.currentExpense;
  },
  watch: {
    currentExpense: function (newV, oldV) {
      this.expense = newV;
      oldV;
    },
  },

  methods: {},
};
</script>