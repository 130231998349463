<template>
<TopNav />
  <SideBar />
 <main class="ml-12 pl-3 mt-5">
    <div class="row mt-5 pt-3">
      <div class="col-md-4"><nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Kullanıcı Menüsü</a></li>
            <li class="breadcrumb-item active" aria-current="page">Aktif Görevlerim</li>
          </ol>
        </nav></div>
      
    </div>
    <br />
<div class="container py-5">
  <div class="row">

    <!-- Start lane -->
    <div 
      class="col-12 col-lg-4"
      @drop="onDrop($event, 2)"
      @dragover.prevent
      @dragenter.prevent
    >
    <div class="card mb-3">
        <div class="card-header bg-light" style="background-color: #01FF70 !important;">
          <h3 class="card-title h5 mb-1">
            Askıda
          </h3>
          <small class="mb-0 text-muted">
            Henüz Çalışmaya Başlamadığınız görevler.
          </small>
        </div>
        <div class="card-body">
          <div class="tasks" id="backlog">
            <!-- Start task -->
            <div 
            class="card mb-3 cursor-grab"
            v-for="item in onHolds"
              :key="item.id"
              draggable="true"
              @dragstart="startDrag($event, item)"
            >
              <div class="card-body">
                
                <h6>#{{item.id}}| {{item.name}}</h6>
                <ul>
                  <li>Baslangic: {{item.start_date}}</li>
                  <li>Bitis: {{item.end_date}}</li>
                 
                  <li>Müşteri: {{item["client.name"]}}</li>
                  <li>Durumu: {{item["status.name"]}}</li>
                  <li>Türü: {{item["task_type.name"]}}</li>
                  <li>Dönemi: {{item["task_period.period"]}}</li>
                </ul>
                
              </div>
            </div>
            <!-- End task -->
            
            
          </div>
          
        </div>
      </div>
    </div>
    <!-- End lane -->
    <div 
      class="col-12 col-lg-4"
      @drop="onDrop($event, 3)"
      @dragover.prevent
      @dragenter.prevent
    >
      <div class="card mb-3">
        <div class="card-header bg-light" style="background-color: #FF851B !important;">
          <h3 class="card-title h5 mb-1">
            Yapılıyor
          </h3>
          <small class="mb-0 text-muted">
            Çalışmaya başladığınız görevler.
          </small>
        </div>
        <div class="card-body">
          <div class="tasks" id="backlog">
            <!-- Start task -->
            <div 
            class="card mb-3 cursor-grab"
            v-for="item in inProgress"
              :key="item.id"
              draggable="true"
              @dragstart="startDrag($event, item)"
            >
              <div class="card-body">
                
                <h6>#{{item.id}}| {{item.name}}</h6>
                <ul>
                  <li>Baslangic: {{item.start_date}}</li>
                  <li>Bitis: {{item.end_date}}</li>
                 
                  <li>Müşteri: {{item["client.name"]}}</li>
                  <li>Durumu: {{item["status.name"]}}</li>
                  <li>Türü: {{item["task_type.name"]}}</li>
                  <li>Dönemi: {{item["task_period.period"]}}</li>
                </ul>
                
              </div>
            </div>
            <!-- End task -->
            
            
          </div>
          
        </div>
      </div>
    </div>

    <!-- End lane -->

    <!-- Start lane -->
  <div 
      class="col-12 col-lg-4"
      @drop="onDrop($event, 4)"
      @dragover.prevent
      @dragenter.prevent
    >
      <div class="card mb-3">
        <div class="card-header bg-light" style="background-color: #00CED1 !important;">
          <h3 class="card-title h5 mb-1">
            Bitti
          </h3>
          <small class="mb-0 text-muted" >
            Bitirdiğiniz; onay bekleyen görevler.
          </small>
        </div>
        <div class="card-body">
          <div class="tasks" id="backlog">
            <!-- Start task -->
            <div 
            class="card mb-3 cursor-grab"
            v-for="item in done"
              :key="item.id"
              draggable="true"
              @dragstart="startDrag($event, item)"
            >
              <div class="card-body">
                
                <h6>#{{item.id}}| {{item.name}}</h6>
                <ul>
                  <li>Baslangic: {{item.start_date}}</li>
                  <li>Bitis: {{item.end_date}}</li>
                 
                  <li>Müşteri: {{item["client.name"]}}</li>
                  <li>Durumu: {{item["status.name"]}}</li>
                  <li>Türü: {{item["task_type.name"]}}</li>
                  <li>Dönemi: {{item["task_period.period"]}}</li>
                </ul>
                <button class="btn btn-success" @click="archiveIt($event, item)">Arşivle</button>
              </div>
            </div>
            <!-- End task -->
            
            
          </div>
          
        </div>
      </div>
    </div>
    <!-- End lane -->

  </div>
</div>
 </main>
</template>

<script>
import TaskDataService from "../services/TaskDataService";
import TopNav from "@/components/TopNav.vue";
import SideBar from "@/components/SideBar.vue";

export default {
  name: "Adnd",
  data() {
    return {
      items: [],
    };
  },
  computed: {
    onHolds() {
      return this.items.filter((item) => item.status_id === 2);
    },
    inProgress() {
      return this.items.filter((item) => item.status_id === 3);
    },
    done() {
      return this.items.filter((item) => item.status_id === 4);
    },
    
  },
  components:{
    TopNav,
    SideBar
  },
  methods: {
    
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.id);
    },
    onDrop(evt, list) {
      const itemID = evt.dataTransfer.getData("itemID");
      const item = this.items.find((item) => item.id == itemID);
      console.log(item.id+'|'+item.status_id)
      item.status_id = list;
      console.log(list+" this is list")
      TaskDataService.update(item.id, {status_id:list})
            .then(response => {
                console.log(response.data)
            })
      console.log(item.id+'|'+item.status_id)
    },
    archiveIt(evt, item){
        let today = new Date();
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

        TaskDataService.update(item.id, {status_id:7, due_date:date})
            .then(response => {
                console.log(response.data)
            })
          
           
           TaskDataService.getMines().then((response) => {
            this.items = response.data
            location.reload();
          });
    }
  },
  created() {
    TaskDataService.getMines().then((response) => {
      this.items = response.data
    });
  },
  
};
</script>

<style scoped>
.drop-zone {
  width: 50%;
  margin: 50px auto;
  background-color: #ecf0f1;
  padding: 10px;
  min-height: 10px;
}
.drag-el {
  background-color: #3498db;
  color: white;
  padding: 5px;
  margin-bottom: 10px;
}
.drag-el:nth-last-of-type(1) {
  margin-bottom: 0;
}
</style>
