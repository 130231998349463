<template>
  <div class="row">
    <div class="col-12">
      <h4 v-if="currentExpense">{{ currentExpense.name }} Bilgileri</h4>
      <div class="responsive-tabs">
        <ul class="nav nav-tabs" role="tablist">
           <li class="nav-item">
            <a
              id="tab-A"
              href="#pane-A"
              class="nav-link active"
              data-bs-toggle="tab"
              role="tab"
              ><i class="bi bi-eye"> Görüntüle</i
            ></a>
          </li>
          
          <li class="nav-item">
            <a
              id="tab-B"
              href="#pane-B"
              class="nav-link "
              data-bs-toggle="tab"
              role="tab"
              ><i class="bi bi-pencil"> Düzenle</i
            ></a>
          </li>
          <li class="nav-item">
            <a
              id="tab-C"
              href="#pane-C"
              class="nav-link"
              data-bs-toggle="tab"
              role="tab"
              ><i class="bi bi-plus-circle"> Yeni</i
            ></a>
          </li>
          
        </ul>

        <div id="content" class="tab-content" role="tablist">
          <div
            id="pane-A"
            class="card tab-pane fade show active"
            role="tabpanel"
            aria-labelledby="tab-A"
          >
            <div class="card-header" role="tab" id="heading-A">
              <div class="card-body">
                <ExpenseBox :currentExpense="this.currentExpense" />
              </div>
            </div>
          </div>
          <div
            id="pane-B"
            class="card tab-pane fade show "
            role="tabpanel"
            aria-labelledby="tab-B"
          >
            <div class="card-header" role="tab" id="heading-B">
              <div class="card-body">
                <ExpenseEdit :currentExpense="currentExpense" @edited="$emit('refreshSent')"/>
              </div>
            </div>
          </div>
          <div
            id="pane-C"
            class="card tab-pane fade"
            role="tabpanel"
            aria-labelledby="tab-C"
          >
            <div class="card-header" role="tab" id="heading-C">
              <div class="card-body">
                <ExpenseNew @newAdded="this.$emit('refreshSent')"/>
              </div>
            </div>
          </div>
         
         
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ExpenseBox from "./ExpenseBox.vue";
import ExpenseEdit from "./ExpenseEdit.vue";
import ExpenseNew from "./ExpenseNew.vue";

export default {
  name: "expenseList",
  props: ["currentExpense"],
  emits:["refreshSent",],
  data() {
    return {
      
    };
  },
  
  

  components: {
    // ClientBox,
    ExpenseEdit,
    ExpenseNew,
    ExpenseBox,
  },
};
</script>

<style scoped>
.bi-dash-circle {
  color: crimson !important;
  size: 40px !important;
}
</style>