import http from "../http-common";
import store from '../store'

class AskDelayService {
    
  getAll() {
    return http.get("/delays",
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
 
  get(id) {
    return http.get(`/delay/${id}`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }


  create(data) {
    return http.post("/delay", data, 
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  update(id, data) {
    return http.put(`/delay/${id}`, data,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  delete(id) {
    return http.delete(`/delay/${id}/`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} });
  }

 
}

export default new AskDelayService();