<template>
<SideBar />
<TopNav />
  <main class="ml-2">
        <div class="row mt-5 pt-5 ms-3">
    <div class="mb-3 col-md-4">
            <h3>Çalışanlar Raporu</h3>
            <form>

                    <label for="start_date" class="form-label">Başlangıç</label>
                <input
                    type="date"
                    class="form-control"
                    id="start_date"
                    v-model="k_start_date"
                />
                <label for="end_date" class="form-label">Bitiş</label>
                <input
                    type="date"
                    class="form-control"
                    id="end_date"
                    v-model="k_end_date"
                />

                <br/>
                <button class="btn btn-primary btn-block" @click="kisiler">
                    Getir
                </button>
          </form>
    </div>
    <!-- calisanlar raporu sonu -->
    <div v-if="is_admin" class="col-md-5 me-3">
            <h3>Müşteri Raporu</h3>
            <label for="client" class="form-label">Müşteri</label>
            <select v-model="client_id" id="client" class="form-control">
                <option
                v-for="client in clients"
                :key="client.id"
                :value="client.id"
                >
                {{ client.name }}
                </option>
            </select>
            <form>

                    <label for="start_date" class="form-label">Başlangıç</label>
                <input
                    type="date"
                    class="form-control"
                    id="start_date"
                    v-model="m_start_date"
                />
                <label for="end_date" class="form-label">Bitiş</label>
                <input
                    type="date"
                    class="form-control"
                    id="end_date"
                    v-model="m_end_date"
                />

                <br/>
                <button class="btn btn-primary btn-block" @click="musteri">
                    Getir
                </button>
          </form>
    </div>
    <!-- bu div odiv-->
    <div v-if="is_admin" class="col-md-5 me-3">
            <h3>Müşteri  Gelir Gider Raporu</h3>
            <form>
            <label for="client" class="form-label">Müşteri</label>
            <select v-model="client_id_g" id="client" class="form-control">
                <option
                v-for="client in clients"
                :key="client.id"
                :value="client.id"
                >
                {{ client.name }}
                </option>
            </select>

            <label for="donem" class="form-label">Dönem</label>
      <select v-model="period" id="donem" class="form-control form-select">
        <option
          v-for="p in project_periods"
          :key="p.id"
          :value="p.period"
        >
          {{ p.period }}
        </option>
      </select>

                <br/>
                <button class="btn btn-primary btn-block" @click="musterigelir">
                    Getir
                </button>
          </form>
    </div>
    
    <!--bu denisik-->
    <div v-if="is_admin" class="col-md-5 me-3">
            <h3>Genel  Gelir Gider Raporu</h3>
            <form>
            
            <label for="donem" class="form-label">Dönem</label>
      <select v-model="periodg" id="donem" class="form-control form-select">
        <option
          v-for="p in project_periods"
          :key="p.id"
          :value="p.period"
        >
          {{ p.period }}
        </option>
      </select>

                <br/>
                <button class="btn btn-primary btn-block" @click="genelgelir">
                    Getir
                </button>
          </form>
    </div>
    <!--bu yöneti anket-->
    <div v-if="is_admin" class="mb-3 col-md-4">
      <h3>Yönetici Çalışma Raporu</h3>
      <form>
        
        <label for="start_date" class="form-label">Başlangıç</label>
        <input
        type="date"
        class="form-control"
        id="start_date"
        v-model="yon_start_date"
        />
        <label for="end_date" class="form-label">Bitiş</label>
        <input
        type="date"
        class="form-control"
        id="end_date"
        v-model="yon_end_date"
        />
        
        <br/>
        <button class="btn btn-primary btn-block" @click="cbanketRapor">
          Getir
        </button>
      </form>
    </div>
    <!-- yönetici anket son-->
    <!--Grup Gelir Gider-->
    <div v-if="is_admin" class="col-md-5 me-3">
            <h3>Grup  Gelir Gider Raporu</h3>
            <form>
            <label for="client2" class="form-label">Grup</label>
            <select v-model="cg_id" id="client" class="form-control">
                <option
                v-for="client in client_groups"
                :key="client.id"
                :value="client.id"
                >
                {{ client.name }}
                </option>
            </select>

            <label for="donem2" class="form-label">Dönem</label>
      <select v-model="period_group" id="donem2" class="form-control form-select">
        <option
          v-for="p in project_periods"
          :key="p.id"
          :value="p.period"
        >
          {{ p.period }}
        </option>
      </select>

                <br/>
                <button class="btn btn-primary btn-block" @click="grupgelir">
                    Getir
                </button>
          </form>
    </div>
    <!--Grup Gelir Gider Son-->
    <!--Grup Bazlı Gelir Gider Raporu-->
    <div v-if="is_admin" class="col-md-5 me-3">
            <h3>Mükellef Grubu Bazlı  Gelir Gider Raporu</h3>
            <form>
            
      <label for="donemgt" class="form-label">Başlangıç Dönemi</label>
      <select v-model="periodgt" id="donemgt" class="form-control form-select">
        <option
          v-for="p in project_periods"
          :key="p.id"
          :value="p.period"
        >
          {{ p.period }}
        </option>
      </select>

      <label for="donemgt1" class="form-label">Bitiş Dönemi</label>
      <select v-model="periodgt1" id="donemgt1" class="form-control form-select">
        <option
          v-for="p in project_periods"
          :key="p.id"
          :value="p.period"
        >
          {{ p.period }}
        </option>
      </select>

                <br/>
                <button class="btn btn-primary btn-block" @click="genelgelirmuk">
                    Getir
                </button>
          </form>
    </div>
    <!--Grup Bazlı Gelir Gider Raporu Son-->
    <!--CİBli Rapor-->
    <div v-if="is_admin" class="col-md-5 me-3">
            <h3>CİB Faktörü Dahil Gelir Gider Raporu</h3>
            <form>
            
      <label for="donemcbl1" class="form-label">Başlangıç Dönemi</label>
      <select v-model="periodcbl1" id="donemcbl1" class="form-control form-select">
        <option
          v-for="p in project_periods"
          :key="p.id"
          :value="p.period"
        >
          {{ p.period }}
        </option>
      </select>

      <label for="donemcbl2" class="form-label">Bitiş Dönemi</label>
      <select v-model="periodcbl2" id="donemcbl2" class="form-control form-select">
        <option
          v-for="p in project_periods"
          :key="p.id"
          :value="p.period"
        >
          {{ p.period }}
        </option>
      </select>

                <br/>
                <button class="btn btn-primary btn-block" @click="cibli">
                    Getir
                </button>
          </form>
    </div>
    <!--CİBli Rapor Son-->
    <div class="mb-3 col-md-4">
            <h3>Anket Doldurmayalanlar</h3>
            <form>

                    <label for="devam_start_date" class="form-label">Başlangıç</label>
                <input
                    type="date"
                    class="form-control"
                    id="devam_start_date"
                    v-model="devam_start_date"
                />
                <label for="devam_end_date" class="form-label">Bitiş</label>
                <input
                    type="date"
                    class="form-control"
                    id="devam_end_date"
                    v-model="devam_end_date"
                />

                <br/>
                <button class="btn btn-primary btn-block" @click="devamrapor">
                    Getir
                </button>
          </form>
    </div>
    

    <!--Grup rapor son-->
  </div>
</main>
</template>
<script>
import SideBar from "@/components/SideBar.vue"
import TopNav from "@/components/TopNav.vue"
import ClientDataService from "../services/ClientDataService";
import StaffDataService from "../services/StaffDataService";


import { mapState } from "vuex";


export default {
  name: 'Raporlar',
  computed: mapState(["project_periods", "client_groups"]),
  data() {
    return {
      
      
      k_start_date: null,
      k_end_date: null,
     
      m_start_date: null,
      m_end_date: null,
     
      client_id: null,
      clients: [],
      period: null,
      client_id_g: null,
      periodg: null,
      periodgt: null,
      periodgt1: null,
      is_admin: false,
      yon_start_date: null,
      yon_end_date: null,
      period_group: null,
      devam_start_date:null,
      devam_end_date: null,
      periodcbl1: null,
      periodcbl2: null
      
    };
  },
  created() {
    ClientDataService.getAll().then(response => {
      this.clients = response.data
    }).catch(e => {
      console.log(e.response.data);
        })
    
        StaffDataService.whoAmI().then(
      response => {
        console.log(response.data.role)
        if(response.data.role=='Admin'){
          this.is_admin = true
        }
        if(response.data.role=='PM'){
          this.is_pm = true
        }
      }
    )
      
  },
  methods: {
    kisiler(){
        this.$router.push({ name:'Kisiler', params:{datefirst:this.k_start_date,
          datesecond: this.k_end_date}})
    },
    musteri(){
        this.$router.push({ name:'ClientReport', params:{id:this.client_id,
          datefirst:this.m_start_date,
          datesecond: this.m_end_date}})
    },
    musterigelir(){
        this.$router.push({ name:'IncomeReport', params:{id:this.client_id_g,
          period:this.period}})
    },
    genelgelir(){
        this.$router.push({ name:'GeneralReport', params:{period:this.periodg}})
    },
    cbanketRapor(){
      this.$router.push({ name:'YonetiRapor', params:{datefirst:this.yon_start_date,
          datesecond: this.yon_end_date}})
    },
    grupgelir(){
      this.$router.push({ name:'GroupIncome', params:{id:this.cg_id,
          period:this.period_group}})
    },
    genelgelirmuk(){
      this.$router.push({ name:'MukRapor', params:{period1:this.periodgt, period2:this.periodgt1}})
    },
    cibli(){
      this.$router.push({ name:'CIBliRapor', params:{period1:this.periodcbl1, period2:this.periodcbl2}})
    },
    devamrapor(){
        this.$router.push({ name:'DevamRapor', params:{datefirst:this.devam_start_date,
          datesecond: this.devam_end_date}})
    },
  },
  components: {
    
    SideBar,
    TopNav
  },
  
};
</script>