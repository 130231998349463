<template>
  <div class="row">
    <div class="mb-3 col-auto" >
      <h5>#{{task.id}} No'lu Görev İçin Erteleme Talebi</h5>
      <br/>
      <br/>
      <label for="bodyF" class="form-label">Açıklama</label>
      <textarea class="form-control" id="bodyF" rows="4" v-model="body"></textarea>
      <label for="desired_date" class="form-label">Istenilen tarih</label>
      <input type="date"  class="form-control" id="desired_date" v-model="desired_date" />
      
      <button class="btn btn-primary btn-block" @click="editClient">
        Duzenle
      </button>
    </div>
  </div>
</template>
<script>
import AskDelayService from "../../services/AskDelayService";


export default {
  name: 'TaskEdit',
  emits: ["created"],
  props: ["currentTask"],
  
  data() {
    return {
      selected: -1,
      task: this.currentTask,
      clients: [],
      body: '',
      desired_date: null
    };
  },
  created() {
    
  },

  watch: {
    currentTask: function (newV, oldV) {
      this.task = newV;
      
      oldV;
    },
  },
  methods: {
    editClient() {
      let data = {
        body:this.body,
        task_date: this.task.end_date,
        desired_date: this.desired_date,
        staff_id: this.task.staff_id,
        task_id: this.task.id
      }
      console.log(data)
      AskDelayService.create( data)
      .then(response => {
        console.log(response.data)
        this.$emit("created")
        this.$notify({
          text: "Erteleme Talebiniz Gönderildi",
          type: "success",
        });
      }).catch(e => {
        console.log(e.response.data)
      })
    },
  },
};
</script>

    
