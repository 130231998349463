import http from "../http-common";
import store from '../store'

class HourDataService {
    
  getAll() {
    return http.get("/hours",
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
  
  getMines() {
    return http.get("/my-hours",
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
  getWithDate(str_date) {
    return http.get(`/my-hours/${str_date}`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
  
  get(id) {
    return http.get(`/hour/${id}`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  create(data) {
    return http.post("/hour", data, 
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  update(id, data) {
    return http.put(`/hour/${id}`, data,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  delete(id) {
    return http.delete(`/hour/${id}/`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} });
  }
  setNew(data){
    return http.post('/new-hour', data,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} })
  }

 
}

export default new HourDataService();