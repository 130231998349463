import http from "../http-common";
import store from '../store'

class SelfServices {
    
  whoAmI() {
    return http.get("/wai",
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
  usersProjects() {
    return http.get("/users-projects",
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
  userinTasks() {
    return http.get("/userin-tasks",
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
  gimme(){
    return http.get("/ad")
  }
  
  myPassword( data) {
    return http.put("/my-pass", data,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
  clientReport(id, datefirst, datesecond){
    return http.get(`/cr/${id}/${datefirst}/${datesecond}`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
  generalStaffReport( datefirst, datesecond){
    return http.get(`/sr/${datefirst}/${datesecond}`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
  staffReport(id, datefirst, datesecond){
    return http.get(`/cr/${id}/${datefirst}/${datesecond}`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }
 
//   get(id) {
//     return http.get(`/delay/${id}`,
//     { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
//     );
//   }


  newizin(data) {
    return http.post("/izin", data, 
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

//   update(id, data) {
//     return http.put(`/delay/${id}`, data,
//     { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
//     );
//   }

  deleteizin(id) {
    return http.delete(`/izin/${id}`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} });
  }

 
}

export default new SelfServices();