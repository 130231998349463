import http from "../http-common";
import store from '../store'

class ProjectDataService {
    
  getAll() {
    return http.get("/projects",
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  get(id) {
    return http.get(`/project/${id}`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  create(data) {
    return http.post("/project", data, 
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  update(id, data) {
    return http.put(`/project/${id}`, data,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} }
    );
  }

  delete(id) {
    return http.delete(`/project/${id}/`,
    { headers: {"Authorization" : `Bearer `+ store.state.access_token} });
  }

 
}

export default new ProjectDataService();