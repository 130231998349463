<template>
  <TopNav />
  <SideBar />
  <main class="ml-12 pl-3">

    <div class="row mt-2">
      <div class="col-md-4"><nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Kullanıcı Menüsü</a></li>
            <li class="breadcrumb-item active" aria-current="page">Günlük Anket</li>
          </ol>
        </nav>
      </div>
      
      
    </div>
    <br />
  <div class="container-fluid">
<div class="row">
  <div class="col-6">
    <h5 v-if="myHours[0]">Günlük Çalışma Anketi ({{myHours[0].date_}})</h5>
  </div>
  <div class="col-6">
    
    
    <div class="dropdown float-end">
      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
        Diğer Günler
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" >
    <li  v-for="(adl, index) in this.ads" :key="index">
    <!-- <router-link :to="{ name: 'DatedQuestionnaire', params: { str_date: adl }}" class="dropdown-item"> {{adl}} </router-link> -->
    <span class="dropdown-item" @click="pushRoute(adl)">{{adl}}</span>
    </li>
  
  </ul>
</div>
    </div>
    </div>  
    <br/>
    <table id="datatable" class="table table-striped table-bordered">
      <thead>
        <tr>
          <th>ID</th>
          <th>Başlık</th>
          <th>Baslangic</th>
          <th>Bitis</th>
          <th>Durum</th>
          <th>Türü</th>
          <th>Dönemi</th>
          <th style="width:30%">Müşteri</th>
          <th>Harcanan Zaman</th>
          <th>Açıklama</th>
        </tr>
      </thead>
      <tbody v-if="myHours[0]">
        <tr v-for="(hour, index) in this.myHours" :key="index">
          <td>{{ hour.id }}</td>
          <td>{{ hour["task.name"] }}</td>
          <td>{{ hour["task.start_date"] }}</td>
          <td>{{ hour["task.end_date"] }}</td>
          <td>{{ hour["task.status.name"] }}</td>
          <td>{{ hour["task.task_type.name"] }}</td>
          <td>{{ hour["task.task_period.period"] }}</td>
          <td>{{ hour["task.client.name"] }}</td>
          <td>
            <button
              :disabled="hour.hour <= 0"
              @click="hour['hour'] = hour['hour'] - 0.5"
            >
              -
            </button>
            {{ hour.hour }}
            <button  @click="hour['hour'] = hour['hour'] + 0.5">+</button>
          </td>
          <td>
            <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" :title="hour['task.description']">
              <button class="btn btn-success"><i class="bi bi-eye"></i></button>
            </span>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="7" class="text-center"><h5>Üzerinizde görev yok veya bu tarihte izinlisiniz.</h5></td>
        </tr>
      </tbody>
    </table>
    <button v-if="myHours[0]" class="btn btn-warning" @click="calc">Gönder</button>
    <br />
    <br />

    <br />

  <!-- <div class="col-md-3">
      <h5>Yeni Çalışma Ekle</h5>
      <form>
        <div class="form-group">
          <label for="exampleFormControlInput1">Başlık</label>
          <input
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            v-model="name"
          />
        </div>

        <div class="form-group">
          <label for="exampleFormControlTextarea1">Açıklama</label>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            v-model="description"
          ></textarea>
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput2">Saat</label>
          <input
            type="number"
            step="0.5"
            class="form-control"
            id="exampleFormControlInput2"
            v-model="hour"
          />
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block form-control" @click="doThis"> Ekle</button>
        </div>
      </form>
    </div>  -->
  </div>
  </main>
</template>
<script>
import StaffDataService from "@/services/StaffDataService";
import HourDataService from "@/services/HourDataService";
import SelfServices from '@/services/SelfServices'
import TopNav from '@/components/TopNav'
import SideBar from '@/components/SideBar'


export default {
  data() {
    return {
      myId: null,
      myHours: [],
      summa: 0,
      name: "",
      description: "",
      hour: 0,
      ads: [],
      str_date: this.$route.params.str_date
    };
  },
  created() {
    SelfServices.gimme().then((response) =>
    {
      this.ads = response.data.dates
      
    })
    StaffDataService.myId().then((response) => {
      this.myId = response.data.id;
      //console.log(response.data.id);
    });
   
    if (this.str_date != undefined){
      HourDataService.getWithDate(this.str_date).then((response) => {
        let temp = response.data;
        //console.log(response.data);
        this.myHours = temp;
        //console.log(this.myHours);
      });

    } else {
      HourDataService.getMines().then((response) => {
        let temp = response.data;
        //console.log(response.data);
        this.myHours = temp;
        //console.log(this.myHours);
        });
        

    }
  },
  components:{
    TopNav,
    SideBar
  },
  methods: {
    pushRoute(adl){
      this.$router.push({ name: 'DatedQuestionnaire', params: { str_date: adl }}) 
      setTimeout(()=>{location.reload()}, 100)
    },
    calc() {
      let total = 0;
      this.myHours.forEach((item) => {
        total = total + item.hour;

        //console.log("#id" + item.id);
      });
      this.summa = total;
      if (total > 16 ) {
        this.$notify({
          text: "Toplam çalışma süresi 16'dan büyük olamaz!",
          type: "warn",
        });
      } else {
        this.myHours.forEach((item) => {
          HourDataService.update(item.id, { hour: item.hour });
        });
        this.$notify({
          text: "Anket Veri Tabanına işlendi",
          type: "success",
        });
      }
      
    },

    doThis(){
      let data = {
        name: this.name,
        description: this.description,
        hour: this.hour
      }
      HourDataService.setNew(data)
      HourDataService.getMines().then((response) => {
      let temp = response.data;
      //console.log(response.data);
      this.myHours = temp;
      //console.log(this.myHours);
    });
    HourDataService.getMines().then((response) => {
      let temp = response.data;
      //console.log(response.data);
      this.myHours = temp;
      //console.log(this.myHours);
    });

}

  },
};
</script>
<style scoped>
form {
  background-color: lightgray;
  border-radius: 4px;
}
</style>