<template>
  <TopNav />
  <SideBar />
   <main class="ml-12 pl-3">
  <div class="row mt-5 pt-5 ms-5">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-6">
        <h5 class="text-center">{{staffName}} Müşteri Bazlı İş Dökümü</h5>
        <h6 class="text-center">{{trh(bas)}} ve {{trh(son)}} tarihleri arası</h6>
      </div>
    </div>
   
    <div class="row">
    <div class="col-md-4">
     
        <router-link class="btn btn-info" :to="{ name: 'Kisiler', 
                      params: { datefirst: this.bas, datesecond: this.son }}"> 
                    Geri Dön 
        </router-link>
     <br/>
     <p></p>
    </div>
    <div class="col-md-6"></div>
    <div class="col-md-2"><button class="btn btn-info" @click="toExcel"><i class="bi bi-download"></i> Excele Aktar</button></div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <table
          id="datatable"
          class="table table-striped table-bordered mt-3 pt-3"
        >
          <thead>
    
            <tr>
              <th>Müşteri</th>
              <th>Toplam Çalışma (Saat)</th>
              <th>Toplam Görev</th>
              <th>Askıda Görevler</th>
              <th>Yapılmakta Görevler</th>
              <th>Biten Görevler</th>
              <th>Arşivlenen Görevler</th>
            </tr>
          </thead>
          <tbody>
            <tr
              :class="{ active: index == currentIndex }"
              v-for="(staff, index) in this.staffItems"
              :key="index" 
            >
            <td>{{staff.client_name}}</td>
            <td>{{binl(staff.sum_hour)}}</td>
            <td>{{staff.task_count}}</td>
            <td>{{staff.task_askida}}</td>
            <td>{{staff.task_doing}}</td>
            <td>{{staff.task_done}}</td>
            <td>{{staff.task_archived}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    

   
  
    </div>
  </div>
   </main>
</template>
<script>
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";


import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import TableToExcel from "table-to-excel/table-to-excel.js"

import $ from "jquery";
import { mapState } from "vuex";
import StaffDataService from "../services/StaffDataService";import TopNav from "@/components/TopNav.vue";
import SideBar from "@/components/SideBar.vue";

export default {
    name: "Kisi",components: {
      TopNav,
      SideBar},
    computed: mapState(["access_token", "api_url"]),
    mounted() {
    StaffDataService.get(this.$route.params.id)
      .then(response => {
        this.staffName=response.data.username
      }).catch(e => {
        console.log(e.response.data)
      })
    const requestParams = {
      method: "GET",
      headers: { Authorization: `Bearer ` + this.access_token },
    };
    fetch(this.api_url+"scr/"+this.staffid+"/"+this.bas+"/"+this.son, requestParams)
      .then((response) => response.json())
      .then((data) => {
        this.staffItems = data;
        
        setTimeout(() => {
          $("#datatable").DataTable({
            language: {
              url: "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Turkish.json",
            },
            lengthMenu: [
              [10, 25, 50, -1],
              [10, 25, 50, "Hepsi"],
            ],
            dom: 'Bfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            pageLength: 100,
          });
        });
      });
  },
  data(){
        return{
            staffid: this.$route.params.id,
            bas: this.$route.params.datefirst,
            son: this.$route.params.datesecond,
            staffItems:[],
            staffName: null
        }
    },
  methods: {
      toExcel(){
        var tableToExcel=new TableToExcel();
        tableToExcel.render("datatable")
      },
      trh(myText){
        let my_arr = myText.split("-")
        return my_arr[2]+"."+my_arr[1]+"."+my_arr[0]
      },
      binl(n) {
       return Number(Number(n).toFixed(2)).toLocaleString('tr-TR')
      }
    }
}
</script>
<style scoped>
@import "~datatables.net-dt/css/jquery.dataTables.min.css";
.active {
  background-color: azure !important;
}
th {
  background-color: lightblue;
  border-radius: 5px !important;
}
</style>