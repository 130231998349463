import axios from "axios";
import store from './store'


export default axios.create({
  baseURL: store.state.api_url,
  headers: {
    "Content-type": "application/json;charset=utf-8",
    
  }
});